import {
  LOAD_USER_INFO,
  RESET_USER_INFO,
  UPDATE_USER_DATA,
  UPDATE_LESSON_PROGRESS,
  UPDATE_EXAM_PROGRESS,
  UPDATE_USER_LANGUAGE,
  UPDATE_LANGUAGE,
  UPDATE_PROBING_QUESTIONS_PROGRESS,
  RESET,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from "./authConstant";
import { createReducer } from "../app/common/utils/reducerUtils";

const initialState = {
  userInfo: null,
  selectedLanguage: "en",
  resetPasswordSuccess: false,
  resetPasswordFailure: false,
  errMsg: ""
};

const loadUserInfo = (state, payload) => {
  return {
    ...state,
    userInfo: payload.userInfo,
    progressInfo: payload.progressInfo
  };
};

const updateUserData = (state, payload) => {
  return {
    ...state,
    userInfo: {
      ...state.userInfo,
      name: payload.values.name,
      gender: payload.values.gender,
      birthday: payload.birthday,
      profileImagePath: payload.profileImagePath,
      profileImageUrl: payload.profileImageUrl
    }
  };
};

const resetUserInfo = (state, payload) => {
  return {
    ...state,
    userInfo: null
  };
};

const updateLanguage = (state, payload) => {
  return {
    ...state,
    selectedLanguage: payload.language
  };
};

const updateUserLanguageInfo = (state, payload) => {
  return {
    ...state,
    userInfo: {
      ...state.userInfo,
      nativeLanguage: payload.language
    }
  };
};

const updateLessonProgress = (state, payload) => {
  // console.log(payload.lessonId);
  if (
    state.progressInfo.lessons &&
    state.progressInfo.lessons[payload.courseId]
  ) {
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        lessons: {
          ...state.progressInfo.lessons,
          [payload.courseId]: {
            ...state.progressInfo.lessons[payload.courseId],
            [payload.lessonId]: payload.lessonIdList
          }
        }
      }
    };
  } else {
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        lessons: {
          ...state.progressInfo.lessons,
          [payload.courseId]: {
            [payload.lessonId]: payload.lessonIdList
          }
        }
      }
    };
  }
};

const updateProbingQuestionProgress = (state, payload) => {
  if (
    state.progressInfo.lessons &&
    state.progressInfo.lessons[payload.questionId]
  ) {
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        lessons: {
          ...state.progressInfo.lessons,
          [payload.courseId]: {
            ...state.progressInfo.lessons.probing_questions,
            probing_questions: payload.lessonIdList
          }
        }
      }
    };
  } else {
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        lessons: {
          ...state.progressInfo.lessons,
          probing_questions: {
            [payload.questionId]: payload.lessonIdList
          }
        }
      }
    };
  }
};

const updateExamProgress = (state, payload) => {
  if (state.progressInfo.exams) {
    // console.log(...state.progressInfo.exams)
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        exams: {
          ...state.progressInfo.exams,
          [payload.examId]: {
            ...state.progressInfo.exams[payload.examId],
            score: payload.currentScore,
            answersIndex: payload.answersIndex
          }
        }
      }
    };
  } else {
    return {
      ...state,
      progressInfo: {
        ...state.progressInfo,
        exams: {
          ...state.progressInfo.exams,
          [payload.examId]: {
            score: payload.currentScore,
            answersIndex: payload.answersIndex
          }
        }
      }
    };
  }
};

const reset = state => {
  return {
    ...state,
    loading: false,
    resetPasswordSuccess: false,
    resetPasswordFailure: false,
    errMsg: ""
  };
};

const resetPasswordStart = state => {
  return {
    ...state,
    loading: true,
    resetPasswordSuccess: false,
    resetPasswordFailure: false,
    errMsg: ""
  };
};

const resetPasswordSuccess = state => {
  return {
    ...state,
    resetPasswordSuccess: true,
    loading: false
  };
};

const resetPasswordFailure = (state, payload) => {
  return {
    ...state,
    resetPasswordFailure: true,
    errMsg: payload.errMsg,
    loading: false
  };
};

export default createReducer(initialState, {
  [LOAD_USER_INFO]: loadUserInfo,
  [RESET_USER_INFO]: resetUserInfo,
  [UPDATE_USER_DATA]: updateUserData,
  [UPDATE_LESSON_PROGRESS]: updateLessonProgress,
  [UPDATE_PROBING_QUESTIONS_PROGRESS]: updateProbingQuestionProgress,
  [UPDATE_EXAM_PROGRESS]: updateExamProgress,
  [UPDATE_USER_LANGUAGE]: updateUserLanguageInfo,
  [UPDATE_LANGUAGE]: updateLanguage,
  [RESET]: reset,
  [RESET_PASSWORD_START]: resetPasswordStart,
  [RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [RESET_PASSWORD_FAILURE]: resetPasswordFailure
});
