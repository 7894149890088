import React, { Fragment } from "react";
import { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PortalDashboard from "../PortalDashboard";
import BusinessSpeaking from "./BusinessSpeaking";
import Ebooks from "./Ebooks";
import EmailTemplates from "./EmailTemplates";
import ExamCert from "./exam/ExamCert";
import Exams from "./exam/Exams";
import Header from "./Header";
import HomePage from "./HomePage";
import ChooseLesson from "./lessons/ChooseLesson";
import Lesson from "./lessons/Lesson";
import LessonCompleted from "./lessons/LessonCompleted";
import EditProfilePage from "./Profile/EditProfilePage";
import ExamQuestions from "./exam/ExamQuestions";
import ProbingQuestions from "./ProbingQuestions";
import ProbingQuestionsList from "./ProbingQuestionsList";
import Resources from "./Resources";
import ReviewQuestions from "./exam/ReviewQuestions";
import ExamFail from "./exam/ExamFail";

class BusinessPortal extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Switch>
          <Route
            path="/business-portal/email-templates"
            component={EmailTemplates}
          />
          <Route
            path="/business-portal/business-speaking"
            component={BusinessSpeaking}
          />
          <Route
            path="/business-portal/exams/:levelId/certificate"
            component={ExamCert}
          />
          <Route
            path="/business-portal/exams/:levelId/fail"
            component={ExamFail}
          />
          <Route
            path="/business-portal/:levelId/review-questions"
            component={ReviewQuestions}
          />
          <Route
            path="/business-portal/exams/:levelId"
            component={ExamQuestions}
          />
          <Route
            path="/business-portal/probing-questions/:questionId/lesson-completed"
            component={LessonCompleted}
          />
          <Route
            path="/business-portal/probing-questions/:questionId"
            component={ProbingQuestionsList}
          />
          <Route
            path="/business-portal/probing-questions"
            component={ProbingQuestions}
          />
          <Route path="/business-portal/resources" component={Resources} />
          {/* <Route path="/business-portal/yearly-income" component={YearlyIncome} /> */}
          {/* <Route path="/business-portal/yearly-income-lesson" component={YearlyIncomeLesson} /> */}
          <Route path="/business-portal/ebooks" component={Ebooks} />
          <Route
            path="/business-portal/sales-funnel"
            component={PortalDashboard}
          />
          <Route
            path="/business-portal/:courseId/choose-lesson"
            component={ChooseLesson}
          />
          <Route
            path="/business-portal/:courseId/:lessonId/lesson-completed"
            component={LessonCompleted}
          />
          <Route
            path="/business-portal/:courseId/:lessonId"
            component={Lesson}
          />
          {/* <Route path="/business-portal/lesson-dnd" component={LessonDragAndDrop} /> */}
          <Route
            path="/business-portal/edit-profile"
            component={EditProfilePage}
          />

          <Route path="/business-portal/exams" component={Exams} />
          <Route exact path="/business-portal" component={HomePage} />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(BusinessPortal);
