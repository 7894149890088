import { Dots } from "@brainhubeu/react-carousel";
import { Button, Carousel, Col, message, Radio, Row } from "antd";
import React, { Component } from "react";
import "./FreeTrial.less";
import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import { freeTrialChoice } from "../auth/authActions";
import {
  APROFESSIONAL,
  LOOKINGTOSHARPENYOURSKILLS,
  ANENTREPRENEUR,
  LOOKINGTOINCREASEYOURINCOME,
  AHOBBYISTORENTHUSIAST,
  JUSTLOOKINGTOOKEEPMYMINDSHARP,
  NONEOFABOVE,
  WELCOME,
  WEREEXCITED,
  STARTUSEDO,
  STARTWHEREYOUARE,
  MARKATING,
  MARKATINGTAKESADAY,
  CHANGEYOURMINDSET,
  NOTINGISIMPOSSIBLE,
  WHICHOFTHESEDESCRIBEYOUBEST,
  TAKEMETOPORTAL,
  CONTINUE
} from "./TranslationConstant";

class FreeTrial extends Component {
  state = {
    page: 0,
    choice: null
  };

  carousel = React.createRef();

  onChange = v => {
    this.carousel.current.goTo(v, false);

    this.setState({
      page: v
    });
  };

  handleChoice = e => {
    console.log(e);
    this.setState({
      choice: e.target.value //check if handle state is working correctly
    });
  };

  continue = async e => {
    if (this.state.page < 4) {
      this.carousel.current.next();
      this.setState({
        page: this.state.page + 1
      });
    } else if (this.state.page === 4) {
      if (this.state.choice) {
        var result = await this.props.freeTrialChoice(this.state.choice);
        if (result) {
          message.error(result.code + " : " + result.message);
        } else {
          this.props.goToBusinessPortal();
          console.log("Running go to MLMBizMastery");
        }
      } else {
        message.error("Please select an option before proceeding!");
      }
    }
  };

  render() {
    const { dbAuth } = this.props;
    return (
      <Row align="middle" className="free-trial-body">
        <Col xs={1} md={2} lg={3} />
        <Col xs={22} md={20} lg={18}>
          <Row align="middle">
            {/* <Col xs={24} lg={12}>
              {this.state.page === !4  (
                <div style={{ border: "2px solid black" }} />
              )}
              </Col> */}

            <Col xs={24} lg={0}>
              {this.state.page === 4 ? (
                <Radio.Group
                  onChange={this.handleChoice}
                  className="radio-group"
                >
                  <Radio.Button
                    value="A professional looking to sharpen your skills and unleash potential for success in business and life"
                    className="choice"
                  >
                    <div className="choice-text">
                      {
                        APROFESSIONAL[
                          dbAuth.userInfo
                            ? dbAuth.userInfo.nativeLanguage
                              ? dbAuth.userInfo.nativeLanguage
                              : "en"
                            : "en"
                        ]
                      }
                    </div>
                    {
                      LOOKINGTOSHARPENYOURSKILLS[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </Radio.Button>
                  <Radio.Button
                    value="An entrepreneur looking to increase your income and influence as a business owner"
                    className="choice"
                  >
                    <div className="choice-text">
                      {
                        ANENTREPRENEUR[
                          dbAuth.userInfo
                            ? dbAuth.userInfo.nativeLanguage
                              ? dbAuth.userInfo.nativeLanguage
                              : "en"
                            : "en"
                        ]
                      }
                    </div>
                    {
                      LOOKINGTOINCREASEYOURINCOME[
                        dbAuth.userInfo.nativeLanguage
                          ? dbAuth.userInfo.nativeLanguage
                          : "en"
                      ]
                    }
                  </Radio.Button>
                  <Radio.Button
                    value="A hobbyist or enthusiast just looking too keep my mind sharp"
                    className="choice"
                  >
                    <div className="choice-text">
                      {
                        AHOBBYISTORENTHUSIAST[
                          dbAuth.userInfo
                            ? dbAuth.userInfo.nativeLanguage
                              ? dbAuth.userInfo.nativeLanguage
                              : "en"
                            : "en"
                        ]
                      }
                    </div>
                    {
                      JUSTLOOKINGTOOKEEPMYMINDSHARP[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </Radio.Button>
                  <Radio.Button value="None of the above" className="choice">
                    {
                      NONEOFABOVE[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </Radio.Button>
                </Radio.Group>
              ) : (
                <div style={{ border: "2px solid black" }} />
              )}
            </Col>

            <Col sm={24} lg={12} style={{ textAlign: "center" }}>
              <Carousel ref={this.carousel} dots={false}>
                <div className="free-trial-card">
                  <div className="free-trial-title">
                    {
                      WELCOME[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }{" "}
                    {dbAuth.userInfo.name}!
                  </div>
                  <br />
                  <div className="free-trial-description">
                    {
                      WEREEXCITED[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </div>
                </div>
                <div className="free-trial-card">
                  <div className="free-trial-title">
                    {
                      STARTUSEDO[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </div>
                  <br />
                  <div className="free-trial-description">
                    {
                      STARTWHEREYOUARE[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </div>
                </div>
                <div className="free-trial-card">
                  <div className="free-trial-title">
                    {
                      MARKATING[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </div>
                  <br />
                  <div className="free-trial-description">
                    {
                      MARKATINGTAKESADAY[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </div>
                </div>
                <div className="free-trial-card">
                  <div className="free-trial-title">
                    {
                      CHANGEYOURMINDSET[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </div>
                  <br />
                  <div className="free-trial-description">
                    {
                      NOTINGISIMPOSSIBLE[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </div>
                </div>
                <div className="free-trial-card">
                  <div className="free-trial-title">
                    {
                      WHICHOFTHESEDESCRIBEYOUBEST[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </div>
                </div>
              </Carousel>
              <Button
                className="freetrial-continue-button"
                onClick={this.continue}
              >
                {this.state.page === 4
                  ? TAKEMETOPORTAL[
                      dbAuth.userInfo
                        ? dbAuth.userInfo.nativeLanguage
                          ? dbAuth.userInfo.nativeLanguage
                          : "en"
                        : "en"
                    ]
                  : CONTINUE[
                      dbAuth.userInfo
                        ? dbAuth.userInfo.nativeLanguage
                          ? dbAuth.userInfo.nativeLanguage
                          : "en"
                        : "en"
                    ]}
              </Button>
              <br />
              <br />
              <Dots
                number="5"
                onChange={v => this.onChange(v)}
                value={this.state.page}
              />
            </Col>
            <Col xs={0} lg={12}>
              {this.state.page === 4 ? (
                <Radio.Group
                  onChange={this.handleChoice}
                  className="radio-group"
                >
                  <Radio.Button
                    value="A professional looking to sharpen your skills and unleash potential for success in business and life"
                    className="choice"
                  >
                    <div className="choice-text">
                      {
                        APROFESSIONAL[
                          dbAuth.userInfo
                            ? dbAuth.userInfo.nativeLanguage
                              ? dbAuth.userInfo.nativeLanguage
                              : "en"
                            : "en"
                        ]
                      }
                    </div>
                    {
                      LOOKINGTOSHARPENYOURSKILLS[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </Radio.Button>
                  <Radio.Button
                    value="An entrepreneur looking to increase your income and influence as a business owner"
                    className="choice"
                  >
                    <div className="choice-text">
                      {
                        ANENTREPRENEUR[
                          dbAuth.userInfo
                            ? dbAuth.userInfo.nativeLanguage
                              ? dbAuth.userInfo.nativeLanguage
                              : "en"
                            : "en"
                        ]
                      }
                    </div>
                    {
                      LOOKINGTOINCREASEYOURINCOME[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </Radio.Button>
                  <Radio.Button
                    value="A hobbyist or enthusiast just looking too keep my mind sharp"
                    className="choice"
                  >
                    <div className="choice-text">
                      {
                        AHOBBYISTORENTHUSIAST[
                          dbAuth.userInfo
                            ? dbAuth.userInfo.nativeLanguage
                              ? dbAuth.userInfo.nativeLanguage
                              : "en"
                            : "en"
                        ]
                      }
                    </div>
                    {
                      JUSTLOOKINGTOOKEEPMYMINDSHARP[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </Radio.Button>
                  <Radio.Button value="None of the above" className="choice">
                    {
                      NONEOFABOVE[
                        dbAuth.userInfo
                          ? dbAuth.userInfo.nativeLanguage
                            ? dbAuth.userInfo.nativeLanguage
                            : "en"
                          : "en"
                      ]
                    }
                  </Radio.Button>
                </Radio.Group>
              ) : (
                <div style={{ border: "2px solid black" }} />
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={1} md={2} lg={3} />
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  dbAuth: state.auth,
  user: state.users
});

const mapDispatchToProps = {
  freeTrialChoice
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(FreeTrial);
