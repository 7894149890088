export const SEARCH = {
  en: "Search",
  ms: "Cari",
  ct: "搜索",
  cs: "搜索",
  th: "ค้นหา",
  in: "Cari"
};

export const DISCOVERMORE = {
  en: "Discover More",
  ms: "Ketahui Lebih Lanjut",
  ct: "發現更多",
  cs: "发现更多",
  th: "ค้นพบเพิ่มเติม",
  in: "Temukan Selengkapnya"
};

export const VIEWCERT = {
  en: "View Certificate",
  ms: "Lihat Sijil",
  ct: "查看證書",
  cs: "查看证书",
  th: "ดูใบรับรอง",
  in: "Lihat Sertifikat"
};

export const STARTNOW = {
  en: "Start Now",
  ms: "Mula Sekarang",
  ct: "現在開始",
  cs: "现在开始",
  th: "เริ่มเลย",
  in: "Mulai sekarang"
};

export const REPEAT = {
  en: "Repeat",
  ms: "Ulang",
  ct: "重複",
  cs: "重复",
  th: "ทำซ้ำ",
  in: "Ulang"
};

export const ALL = {
  en: "All",
  ms: "Semua",
  ct: "全部",
  cs: "全部",
  th: "ทั้งหมด",
  in: "Semua"
};

export const SEEMORE = {
  en: "see more",
  ms: "lihat lagi",
  ct: "查看更多",
  cs: "查看更多",
  th: "ดูเพิ่มเติม",
  in: "lihat lebih banyak"
};

export const CONTACTUS = {
  en: "Contact Us",
  ms: "Hubung Kami",
  ct: "聯繫我們",
  cs: "联系我们",
  th: "ติดต่อเรา",
  in: "Hubung Kami"
};

export const ROWPERPAGE = {
  en: "Rows per page",
  ms: "Baris setiap halaman",
  ct: "每頁行數",
  cs: "每页行数",
  th: "แถวต่อหน้า",
  in: "Baris setiap halaman"
};

export const OF = {
  en: "of",
  ms: "dari",
  ct: "的",
  cs: "的",
  th: "ของ",
  in: "dari"
};

export const COURSES = {
  en: "Courses",
  ms: "Kursus",
  ct: "課程",
  cs: "课程",
  th: "หลักสูตร",
  in: "Kursus"
};

export const LEARNINGPATH = {
  en: "LEARNING PATH",
  ms: "PEMBELAJARAN",
  ct: "學習路徑",
  cs: "学习路径",
  th: "เส้นทางการเรียนรู้",
  in: "JALAN BELAJAR"
};

export const REVIEWQUEST = {
  en: "REVIEW QUESTION",
  ms: "ULASAN SOALAN",
  ct: "複習題",
  cs: "复习题",
  th: "คำถามทบทวน",
  in: "PERTANYAAN TINJAUAN"
};

export const PRINT = {
  en: "PRINT",
  ms: "CETAK",
  ct: "打印",
  cs: "打印",
  th: "พิมพ์",
  in: "MENCETAK"
};

export const DONE = {
  en: "DONE",
  ms: "SELESAI",
  ct: "完畢",
  cs: "完毕",
  th: "เสร็จแล้ว",
  in: "SELESAI"
};

export const CONGRATULATION = {
  en: "Congratulations! You have passed the Exam!",
  ms: "Tahniah! Anda telah lulus Peperiksaan!",
  ct: "恭喜！ 您已通過考試！",
  cs: "恭喜！ 您已通过考试！",
  th: "ยินดีด้วย! คุณสอบผ่านแล้ว!",
  in: "Selamat! Anda telah lulus Ujian!"
};

export const HEREYOURCERT = {
  en: "Here is your certificate",
  ms: "Inilah sijil anda",
  ct: "這是你的證書",
  cs: "这是你的证书",
  th: "นี่คือใบรับรองของคุณ",
  in: "Ini sertifikatmu"
};

export const BACK = {
  en: "Back",
  ms: "Belakang",
  ct: "後退",
  cs: "后退",
  th: "กลับ",
  in: "Kembali"
};

export const CONTINUE = {
  en: "CONTINUE",
  ms: "TERUSKAN",
  ct: "繼續",
  cs: "继续",
  th: "ดำเนินต่อ",
  in: "MELANJUTKAN"
};

export const PREVIOUS = {
  en: "PREVIOUS",
  ms: "SEBULUMNYA",
  ct: "以前的",
  cs: "以前的",
  th: "ก่อนหน้า",
  in: "SEBULUMNYA"
};

export const CHECKANSWER = {
  en: "CHECK ANSWER",
  ms: "SEMAK JAWAPAN",
  ct: "檢查答案",
  cs: "检查答案",
  th: "ตรวจคำตอบ",
  in: "CEK JAWABAN"
};

export const LESSONS = {
  en: "Lessons",
  ms: "Pengajaran",
  ct: "教訓",
  cs: "教训",
  th: "บทเรียน",
  in: "Pelajaran"
};

export const LESSON = {
  en: "Lesson",
  ms: "Pengajaran",
  ct: "教訓",
  cs: "教训",
  th: "บทเรียน",
  in: "Pelajaran"
};

export const LESSONCOMPLETE = {
  en: "Lesson Complete",
  ms: "Pelajaran Selesai",
  ct: "課程完成",
  cs: "课程完成",
  th: "บทเรียนเสร็จสมบูรณ์",
  in: "Pelajaran Selesai"
};

export const GREATSTART = {
  en: "A great start to your learning journey!",
  ms: "Permulaan yang baik untuk perjalanan pembelajaran anda!",
  ct: "您的學習之旅的良好開端！",
  cs: "您的学习之旅的良好开端！",
  th: "การเริ่มต้นเส้นทางการเรียนรู้ที่ดีของคุณ!",
  in: "Awal yang baik untuk perjalanan belajar Anda!"
};

export const REDO = {
  en: "Redo Lesson",
  ms: "Ulangi Pelajaran",
  ct: "重做課",
  cs: "重做课",
  th: "ทำซ้ำบทเรียน",
  in: "Ulangi Pelajaran"
};

export const CHOOSEEMAIL = {
  en: "Choose an Email template from the list below",
  ms: "Pilih templat E-mel dari senarai di bawah",
  ct: "從下面的列表中選擇一個電子郵件模板",
  cs: "从下面的列表中选择一个电子邮件模板",
  th: "เลือกเทมเพลตอีเมลจากรายการด้านล่าง",
  in: "Pilih template Email dari daftar di bawah ini"
};

export const BUSINESSPHRASES = {
  en: "Business Phrases",
  ms: "Frasa Perniagaan",
  ct: "商務用語",
  cs: "商务用语",
  th: "วลีธุรกิจ",
  in: "Frasa Bisnis"
};

export const DOWNLOADNOW = {
  en: "Download Now",
  ms: "Muat turun sekarang",
  ct: "現在下載",
  cs: "现在下载",
  th: "ดาวน์โหลดเดี๋ยวนี้",
  in: "Unduh sekarang"
};

export const FREEYOUTUBE = {
  en: "Free Youtube Tutorials",
  ms: "Tutorial Youtube percuma",
  ct: "免費 YouTube 教程",
  cs: "免费 YouTube 教程",
  th: "บทช่วยสอน Youtube ฟรี",
  in: "Tutorial Youtube Gratis"
};

export const YOUTUBEDESC = {
  en:
    "Your business journey is about to start! Find out more by watching our YouTube tutorials.",
  ms:
    "Perjalanan perniagaan anda akan bermula! Ketahui lebih lanjut dengan menonton tutorial YouTube kami.",
  ct: "您的創業之旅即將開始！ 通過觀看我們的 YouTube 教程了解更多信息。",
  cs: "您的创业之旅即将开始！ 通过观看我们的 YouTube 教程了解更多信息。",
  th:
    "เส้นทางธุรกิจของคุณกำลังจะเริ่มต้นขึ้น! ค้นหาข้อมูลเพิ่มเติมโดยดูบทแนะนำ YouTube ของเรา",
  in:
    "Perjalanan bisnis Anda akan segera dimulai! Cari tahu lebih lanjut dengan menonton tutorial YouTube kami."
};

export const CLICKHERE = {
  en: "Click here",
  ms: "Tekan di sini",
  ct: "點擊這裡",
  cs: "点击这里",
  th: "คลิกที่นี่",
  in: "Klik disini"
};

export const ADDSALESFUNNEL = {
  en: "+ Sales Funnel",
  ms: "+ Corong Jualan",
  ct: "+ 銷售漏斗",
  cs: "+ 销售漏斗",
  th: "+ ช่องทางการขาย",
  in: "+ Saluran Penjualan"
};

export const CREATEANDEDIT = {
  en: "Create & edit sales funnel & download E-book here.",
  ms: "Buat & edit corong jualan & muat turun E-book di sini.",
  ct: "在此處創建和編輯銷售漏斗並下載電子書。",
  cs: "在此处创建和编辑销售漏斗并下载电子书。",
  th: "สร้างและแก้ไขช่องทางการขายและดาวน์โหลด E-book ที่นี่",
  in: "Buat & edit saluran penjualan & unduh E-book di sini."
};

export const EDITFUNNEL = {
  en: "Edit Funnel",
  ms: "Edit Corong",
  ct: "編輯漏斗",
  cs: "编辑漏斗",
  th: "แก้ไขช่องทาง",
  in: "Sunting Corong"
};

export const DOWNLOADEBOOK = {
  en: "Download E-book",
  ms: "Muat turun E-book",
  ct: "下載電子書",
  cs: "下载电子书",
  th: "ดาวน์โหลด E-book",
  in: "Unduh E-book"
};

export const DOMAINCONFIG = {
  en: "Domain Configuration",
  ms: "Konfigurasi Domain",
  ct: "域配置",
  cs: "域配置",
  th: "การกำหนดค่าโดเมน",
  in: "Konfigurasi Domain"
};

export const CONFIGSUBDOMAIN = {
  en: "Below is subdomain that you have configured.",
  ms: "Di bawah ini adalah subdomain yang telah anda konfigurasikan.",
  ct: "下面是您配置的子域。",
  cs: "下面是您配置的子域。",
  th: "ด้านล่างนี้คือโดเมนย่อยที่คุณกำหนดค่าไว้",
  in: "Di bawah ini adalah subdomain yang telah Anda konfigurasikan."
};

export const BUILDYOURFUNNEL = {
  en: "Congratulations! You have built your Funnel. You are ready to publish!",
  ms:
    "Tahniah! Anda telah membina Corong anda. Anda sudah bersedia untuk menerbitkan!",
  ct: "恭喜！ 你已經建立了你的漏斗。 您已準備好發布！",
  cs: "恭喜！ 你已经建立了你的漏斗。 您已准备好发布！",
  th: "ยินดีด้วย! คุณได้สร้างช่องทางของคุณแล้ว คุณพร้อมที่จะเผยแพร่!",
  in:
    "Selamat! Anda telah membangun Corong Anda. Anda siap untuk mempublikasikan!"
};

export const CHECKAVAILABITIYSUBDOMAIN = {
  en: "Check Availability of Sub-domain",
  ms: "Periksa Ketersediaan Sub-domain",
  ct: "檢查子域的可用性",
  cs: "检查子域的可用性",
  th: "ตรวจสอบความพร้อมใช้งานของโดเมนย่อย",
  in: "Periksa Ketersediaan Sub-domain"
};

export const SUBDOMAINISAVAILABLE = {
  en: "Subdomain is available",
  ms: "Subdomain tersedia",
  ct: "子域可用",
  cs: "子域可用",
  th: "โดเมนย่อยพร้อมใช้งาน",
  in: "Subdomain tersedia"
};

export const SUBDOMAINISUNAVAILABLE = {
  en: "Subdomain is unavailable",
  ms: "Subdomain tidak tersedia",
  ct: "子域不可用",
  cs: "子域不可用",
  th: "โดเมนย่อยไม่พร้อมใช้งาน",
  in: "Subdomain tidak tersedia"
};

export const CURRENTSUBDOMAIN = {
  en: "Current sub-domain",
  ms: "Sub-domain semasa",
  ct: "當前子域",
  cs: "当前子域",
  th: "โดเมนย่อยปัจจุบัน",
  in: "Subdomain saat ini"
};

export const BUYADOMAININSTEED = {
  en: "Buy a domain instead",
  ms: "Sebaliknya, beli domain",
  ct: "改為購買域名",
  cs: "改为购买域名",
  th: "ซื้อโดเมนแทน",
  in: "Beli domain saja"
};

export const HERESSTEPS = {
  en: "Here the steps",
  ms: "Inilah langkahnya",
  ct: "這裡的步驟",
  cs: "这里的步骤",
  th: "นี่คือขั้นตอน",
  in: "Berikut langkah-langkahnya"
};

export const SELECTLANGUAGE = {
  en: "Select Language",
  ms: "Pilih Bahasa",
  ct: "選擇語言",
  cs: "选择语言",
  th: "เลือกภาษา",
  in: "Pilih bahasa"
};

export const SELECTEBOOK = {
  en: "Select an E-Book",
  ms: "Pilih E-Book",
  ct: "選擇電子書",
  cs: "选择电子书",
  th: "เลือก E-Book",
  in: "Pilih E-Book"
};

export const CUSTOMIZEFUNNEL = {
  en: "Customize Funnel",
  ms: "Suaikan Corong",
  ct: "自定義漏斗",
  cs: "自定义漏斗",
  th: "ปรับแต่งช่องทาง",
  in: "Sesuaikan Corong"
};

export const REPUBLISHFUNNEL = {
  en: "Republish Funnel",
  ms: "Terbitkan Corong",
  ct: "重新發布漏斗",
  cs: "重新发布漏斗",
  th: "ช่องทางเผยแพร่ซ้ำ",
  in: "Terbitkan Ulang Corong"
};

export const PUBLISHFUNNEL = {
  en: "Publish Funnel",
  ms: "Terbitkan Corong",
  ct: "發布漏斗",
  cs: "发布漏斗",
  th: "ช่องทางการเผยแพร่",
  in: "Terbitkan Corong"
};

export const YOUHAVEPUBLISHEDFUNNEL = {
  en: "Congratulations! You have published your Funnel.",
  ms: "Tahniah! Anda telah menerbitkan Corong anda.",
  ct: "恭喜！ 您已發布您的漏斗。",
  cs: "恭喜！ 您已发布您的漏斗。",
  th: "ยินดีด้วย! คุณได้เผยแพร่ช่องทางของคุณ",
  in: "Selamat! Anda telah memublikasikan Corong Anda."
};

export const NEXT = {
  en: "Next",
  ms: "Seterusnya",
  ct: "下一個",
  cs: "下一个",
  th: "ถัดไป",
  in: "Berikutnya"
};

export const GOBACK = {
  en: "Go Back",
  ms: "Kembali",
  ct: "回去",
  cs: "回去",
  th: "กลับไป",
  in: "Kembali"
};

export const CUTOMISETHEFUNNELPAGE = {
  en: "Customize the Funnel page",
  ms: "Sesuaikan halaman Corong",
  ct: "自定義漏斗頁面",
  cs: "自定义漏斗页面",
  th: "ปรับแต่งหน้าช่องทาง",
  in: "Sesuaikan halaman Corong"
};

export const INTHISSTEP = {
  en: "In this step, you will customize finalize your final funnel page.",
  ms: "Dalam langkah ini, anda akan menyesuaikan halaman corong akhir anda.",
  ct: "在此步驟中，您將自定義完成最終渠道頁面。",
  cs: "在此步骤中，您将自定义完成最终渠道页面。",
  th: "ในขั้นตอนนี้ คุณจะปรับแต่งหน้าช่องทางสุดท้ายให้เสร็จสิ้น",
  in:
    "Pada langkah ini, Anda akan menyesuaikan finalisasi halaman saluran akhir Anda."
};

export const SAVE = {
  en: "Save",
  ms: "Simpan",
  ct: "節省",
  cs: "节省",
  th: "บันทึก",
  in: "Simpan"
};

export const LEVEL = {
  en: "Level",
  ms: "Tahap",
  ct: "等級",
  cs: "阶段",
  th: "ระดับ",
  in: "Tingkat"
};

export const BESTSELLER = {
  en: "Best Seller",
  ms: "Penjual Terbaik",
  ct: "暢銷書",
  cs: "畅销书",
  th: "ขายดี",
  in: "Penjual Terbaik"
};

export const COURSETAB = {
  en: "Courses",
  ms: "Kursus",
  ct: "課程",
  cs: "课程",
  th: "หลักสูตร",
  in: "Kursus"
};

export const EXAMTAB = {
  en: "Exams",
  ms: "Ujian",
  ct: "考試",
  cs: "考试",
  th: "ข้อสอบ",
  in: "Ujian"
};

export const SALESFUNNELTAB = {
  en: "Sales Funnel",
  ms: "Corong Jualan",
  ct: "銷售漏斗",
  cs: "销售漏斗",
  th: "ช่องทางการขาย",
  in: "Saluran Penjualan"
};

export const EBOOKTAB = {
  en: "E-Books",
  ms: "E-Buku",
  ct: "電子書",
  cs: "电子书",
  th: "E-Books",
  in: "E-Buku"
};

export const PROBINGQUESTIONTAB = {
  en: "Probing Questions",
  ms: "Soalan Siasat",
  ct: "探討問題",
  cs: "探讨问题",
  th: "คำถามเชิงลึก",
  in: "Pertanyaan Menyelidiki"
};

export const EMAILTEMPLATESTAB = {
  en: "Email Templates",
  ms: "Templat E-mel",
  ct: "電子郵件模板",
  cs: "电子邮件模板",
  th: "เทมเพลตอีเมล",
  in: "Template Email"
};

export const BUSINESSSPEAKINGTAB = {
  en: "Business Speaking",
  ms: "Pengucapan Perniagaan",
  ct: "商務演講",
  cs: "商务演讲",
  th: "การพูดทางธุรกิจ",
  in: "Berbicara Bisnis"
};

export const RESOURCESTAB = {
  en: "Resources",
  ms: "Sumber",
  ct: "資源",
  cs: "资源",
  th: "ทรัพยากร",
  in: "Sumber daya"
};

export const COURSETABTITLE = {
  en: "Select a course to get started",
  ms: "Pilih kursus untuk memulakan",
  ct: "選擇一門課程開始",
  cs: "选择一门课程开始",
  th: "เลือกหลักสูตรเพื่อเริ่มต้น",
  in: "Pilih kursus untuk memulai"
};

export const EXAMTABTITLE = {
  en:
    "These exams are your opportunity at proving your worth to everyone around you. Grab it and do your best, don’t let it pass through. Good Luck.",
  ms:
    "Ujian ini adalah peluang anda untuk membuktikan nilai anda kepada semua orang di sekitar anda. Rebutlah dan lakukan yang terbaik, jangan biarkan ia melaluinya. Semoga berjaya.",
  ct:
    "這些考試是您向周圍所有人證明自己價值的機會。 抓住它並儘力而為，不要讓它通過。 祝你好運。",
  cs:
    "这些考试是您向周围所有人证明自己价值的机会。 抓住它并尽力而为，不要让它通过。 祝你好运。",
  th:
    "การสอบเหล่านี้เป็นโอกาสของคุณในการพิสูจน์คุณค่าของคุณต่อทุกคนรอบตัวคุณ คว้ามันและทำให้ดีที่สุดอย่าปล่อยให้มันผ่านไป โชคดี.",
  in:
    "Ujian ini adalah kesempatan Anda untuk membuktikan nilai Anda kepada semua orang di sekitar Anda. Raih dan lakukan yang terbaik, jangan biarkan berlalu begitu saja. Semoga berhasil."
};

export const PROBINGQUESTIONTABTITLE = {
  en: "What are Probing Questions?",
  ms: "Apakah Soalan Siasat?",
  ct: "什麼是試探性問題？",
  cs: "什么是试探性问题？",
  th: "คำถามเชิงลึกคืออะไร?",
  in: "Apa itu Pertanyaan Menyelidiki?"
};

export const EMAILTEMPLATESTABTITLE = {
  en: "Free email templates for every purpose.",
  ms: "Templat e-mel percuma untuk setiap tujuan.",
  ct: "用於各種目的的免費電子郵件模板。",
  cs: "用于各种目的的免费电子邮件模板。",
  th: "เทมเพลตอีเมลฟรีสำหรับทุกวัตถุประสงค์",
  in: "Template email gratis untuk setiap tujuan."
};

export const BUSINESSSPEAKINGTABTITLE = {
  en: "Learn with Native Speakers",
  ms: "Belajar dengan Penceramah Asli",
  ct: "與母語人士學習",
  cs: "与母语人士学习",
  th: "เรียนกับเจ้าของภาษา",
  in: "Belajar dengan Penutur Asli"
};

export const RESOURCESTABTITLE = {
  en: "The Marketing Resources Hub",
  ms: "Hab Sumber Pemasaran",
  ct: "營銷資源中心",
  cs: "营销资源中心",
  th: "ศูนย์กลางทรัพยากรการตลาด",
  in: "Pusat Sumber Daya Pemasaran"
};

export const PROBINGQUESTIONTABDESC = {
  en:
    "When attempting to understand new information, knowing how to ask the right questions is a valuable skill that can facilitate the process. Probing questions are designed to deepen the knowledge and understanding of information for the person asking the question as well as the person answering. The questions themselves provide depth and insight just as much as their answers.",
  ms:
    "Semasa cuba memahami maklumat baru, mengetahui cara mengemukakan soalan yang tepat adalah kemahiran berharga yang dapat memudahkan prosesnya. Soalan probing dirancang untuk memperdalam pengetahuan dan pemahaman maklumat bagi orang yang menanyakan soalan dan juga orang yang menjawab. Soalan-soalan itu sendiri memberikan kedalaman dan pandangan sama seperti jawapan mereka.",
  ct:
    "在嘗試理解新信息時，知道如何提出正確的問題是一項可以促進這一過程的寶貴技能。 探索性問題旨在加深提問者和回答者對信息的知識和理解。 問題本身提供的深度和洞察力與他們的答案一樣多。",
  cs:
    "在尝试理解新信息时，知道如何提出正确的问题是一项可以促进这一过程的宝贵技能。 探索性问题旨在加深提问者和回答者对信息的知识和理解。 问题本身提供的深度和洞察力与他们的答案一样多。",
  th:
    "เมื่อพยายามทำความเข้าใจข้อมูลใหม่ การรู้วิธีถามคำถามที่ถูกต้องเป็นทักษะที่มีคุณค่าที่สามารถอำนวยความสะดวกในกระบวนการได้ คำถามแบบสำรวจได้รับการออกแบบมาเพื่อเพิ่มความรู้และความเข้าใจในข้อมูลให้ลึกซึ้งยิ่งขึ้นสำหรับผู้ถามคำถามและผู้ตอบคำถาม ตัวคำถามเองให้ความลึกและความเข้าใจมากพอๆ กับคำตอบของพวกเขา",
  in:
    "Saat mencoba memahami informasi baru, mengetahui cara mengajukan pertanyaan yang tepat adalah keterampilan berharga yang dapat memfasilitasi prosesnya. Pertanyaan menyelidik dirancang untuk memperdalam pengetahuan dan pemahaman informasi bagi orang yang mengajukan pertanyaan serta orang yang menjawab. Pertanyaan-pertanyaan itu sendiri memberikan kedalaman dan wawasan sama seperti jawaban mereka."
};

export const EMAILTEMPLATESTABDESC = {
  en:
    "Here you can find responsive email templates for any email campaign, from newsletters to transactional emails. Feel free to use these email template for your next campaign.",
  ms:
    "Di sini anda dapat mencari templat e-mel responsif untuk sebarang kempen e-mel, dari buletin hingga e-mel transaksi. Jangan ragu untuk menggunakan templat e-mel ini untuk kempen anda yang seterusnya.",
  ct:
    "在這裡，您可以找到適用於任何電子郵件活動的響應式電子郵件模板，從簡報到交易電子郵件。 請隨意在您的下一個活動中使用這些電子郵件模板。",
  cs:
    "在这里，您可以找到适用于任何电子邮件活动的响应式电子邮件模板，从简报到交易电子邮件。 请随意将这些电子邮件模板用于您的下一个活动。",
  th:
    "คุณจะพบเทมเพลตอีเมลแบบโต้ตอบสำหรับแคมเปญอีเมลใดๆ จากจดหมายข่าวไปจนถึงอีเมลธุรกรรมได้ที่นี่ อย่าลังเลที่จะใช้เทมเพลตอีเมลเหล่านี้สำหรับแคมเปญถัดไปของคุณ",
  in:
    "Di sini Anda dapat menemukan template email responsif untuk kampanye email apa pun, mulai dari buletin hingga email transaksional. Jangan ragu untuk menggunakan template email ini untuk kampanye Anda berikutnya."
};

export const BUSINESSSPEAKINGTABDESC = {
  en:
    "Whether you’re looking to live like a local on your next holiday, pass an exam with flying colors, or ace that business negotiation, you’ve come to the right place. Click on any of following phrase and watch your language skills grow.",
  ms:
    "Sama ada anda ingin hidup seperti orang tempatan pada percutian anda yang seterusnya, lulus ujian dengan warna-warna terbang, atau sebagai rundingan perniagaan, anda telah sampai di tempat yang tepat. Klik pada salah satu frasa berikut dan saksikan kemahiran berbahasa anda berkembang.",
  ct:
    "無論您是想在下一個假期像當地人一樣生活，還是希望通過出色的考試，或者在商務談判中取得成功，您都來對地方了。 單擊以下任何短語並觀察您的語言技能增長。",
  cs:
    "无论您是想在下一个假期像当地人一样生活，还是希望通过出色的考试，或者在商务谈判中取得成功，您都来对地方了。 单击以下任何短语并观察您的语言技能增长。",
  th:
    "ไม่ว่าคุณกำลังมองหาการใช้ชีวิตเหมือนคนในท้องถิ่นในวันหยุดครั้งหน้า สอบผ่านด้วยสีสันที่สดใส หรือเอาชนะการเจรจาธุรกิจ คุณมาถูกที่แล้ว คลิกที่วลีใดๆ ต่อไปนี้ และดูทักษะทางภาษาของคุณเติบโตขึ้น",
  in:
    "Baik Anda ingin hidup seperti penduduk setempat pada liburan berikutnya, lulus ujian dengan gemilang, atau menguasai negosiasi bisnis, Anda telah datang ke tempat yang tepat. Klik salah satu frasa berikut dan saksikan keterampilan bahasa Anda berkembang."
};

export const RESOURCESTABDESC = {
  en: "Everything you need for marketing that’s on point.",
  ms: "Semua yang anda perlukan untuk pemasaran yang tepat.",
  ct: "營銷所需的一切都恰到好處。",
  cs: "营销所需的一切都恰到好处。",
  th: "ทุกสิ่งที่คุณต้องการสำหรับการตลาดที่ตรงประเด็น",
  in: "Semua yang Anda butuhkan untuk pemasaran yang tepat sasaran."
};

export const NAME = {
  en: "Name",
  ms: "Nama",
  ct: "名稱",
  cs: "名称",
  th: "ชื่อ",
  in: "Nama"
};

export const EMAILADD = {
  en: "Email Address",
  ms: "Alamat emel",
  ct: "電子郵件地址",
  cs: "电子邮件地址",
  th: "ที่อยู่อีเมล",
  in: "Alamat email"
};

export const MESSAGE = {
  en: "Message",
  ms: "Mesej",
  ct: "信息",
  cs: "信息",
  th: "ข้อความ",
  in: "Pesan"
};

export const INVALIDEMAIL = {
  en: "The input is not a valid email!",
  ms: "Input bukan e-mel yang sah!",
  ct: "輸入的不是有效的電子郵件！",
  cs: "输入的不是有效的电子邮件！",
  th: "ข้อมูลที่ป้อนไม่ใช่อีเมลที่ถูกต้อง!",
  in: "Masukan bukan email yang valid!"
};

export const PLEASEENTERFULLNAME = {
  en: "Please enter your full name",
  ms: "Sila masukkan nama penuh anda",
  ct: "請輸入您的全名",
  cs: "请输入您的全名",
  th: "กรุณาใส่ชื่อเต็มของคุณ",
  in: "Silakan masukkan nama lengkap Anda"
};

export const PLEASEENTEREMAIL = {
  en: "Please enter a valid email address",
  ms: "Sila masukkan alamat emel yang sah",
  ct: "請輸入有效的電子郵件地址",
  cs: "请输入有效的电子邮件地址",
  th: "กรุณาใส่อีเมล์ที่ถูกต้อง",
  in: "silakan isi alamat email"
};

export const MESSAGEISEMPTY = {
  en: "Message is empty",
  ms: "Mesej kosong",
  ct: "留言為空",
  cs: "留言为空",
  th: "ข้อความว่างเปล่า",
  in: "Pesan kosong"
};

export const SEND = {
  en: "Send",
  ms: "Hantar",
  ct: "發送",
  cs: "发送",
  th: "ส่ง",
  in: "Mengirim"
};

export const GOODMORNING = {
  en: "Good Morning",
  ms: "Selamat Pagi",
  ct: "早上好",
  cs: "早上好",
  th: "อรุณสวัสดิ์",
  in: "Selamat Pagi"
};

export const GOODAFTERNOON = {
  en: "Good Afternoon",
  ms: "Selamat Petang",
  ct: "下午好",
  cs: "早上好",
  th: "สวัสดีตอนบ่าย",
  in: "Selamat Siang"
};

export const GOODEVENING = {
  en: "Good Evening",
  ms: "Selamat Petang",
  ct: "晚上好",
  cs: "早上好",
  th: "สวัสดีตอนเย็น",
  in: "Selamat Malam"
};

export const BUSINESSPORTAL = {
  en: "MLMBizMastery",
  ms: "Portal Perniagaan",
  ct: "商業門戶",
  cs: "商业门户",
  th: "พอร์ทัลธุรกิจ",
  in: "Portal Bisnis"
};

export const WELCOMETOBUSINESSPORTAL = {
  en: "Welcome to MLMBizMastery",
  ms: "Selamat Datang ke MLMBizMastery",
  ct: "歡迎來到 MLMBizMastery",
  cs: "欢迎来到 MLMBizMastery",
  th: "ยินดีต้อนรับสู่ MLMBizMastery",
  in: "Selamat datang ke MLMBizMastery"
};

export const HOWAREHUMANBEINGREMARKABLE = {
  en: "How are human beings remarkable?",
  ms: "Bagaimana manusia luar biasa",
  ct: "人類有什麼了不起",
  cs: "人类有什么了不起",
  th: "มนุษย์มีความโดดเด่นอย่างไร",
  in: "Bagaimana manusia luar biasa?"
};

export const MAINANSWER = {
  en:
    "Human beings have the ability to turn a non-entity into a useful and productive entity just by focusing their minds on it. It is this special ability that sets them apart from other beings and makes them remarkable and unique. Unlike other creatures, humans have the ability to learn and apply their knowledge in their daily lives. With their sense of belief, they can achieve whatever their minds conceive and change anything that they wish.",
  ms:
    "Manusia mempunyai kemampuan untuk mengubah bukan entiti menjadi entiti yang berguna dan produktif hanya dengan memusatkan pemikiran mereka ke atasnya. Keupayaan istimewa inilah yang membezakan mereka dari makhluk lain dan menjadikannya luar biasa dan unik. Tidak seperti makhluk lain, manusia mempunyai kemampuan untuk belajar dan mengaplikasikan pengetahuan mereka dalam kehidupan seharian. Dengan rasa percaya, mereka dapat mencapai apa sahaja yang difikirkan oleh fikiran mereka dan mengubah apa sahaja yang mereka inginkan.",
  ct:
    "人類有能力把一個非實體變成一個有用的和有生產力的實體，只要把他們的注意力集中在它上面。 正是這種特殊能力使他們與其他生物區分開來，並使他們與眾不同。 與其他生物不同，人類有能力在日常生活中學習和應用他們的知識。 憑藉他們的信念感，他們可以實現他們的想法，並改變他們想要的任何東西。",
  cs:
    "人类有能力把一个非实体变成一个有用的和有生产力的实体，只要把他们的注意力集中在它上面。 正是这种特殊能力使他们与其他生物区分开来，并使他们与众不同。 与其他生物不同，人类有能力在日常生活中学习和应用他们的知识。 凭借他们的信念感，他们可以实现他们的想法，并改变他们想要的任何东西。",
  th:
    "มนุษย์มีความสามารถที่จะเปลี่ยนสิ่งที่ไม่ใช่ตัวตนให้กลายเป็นสิ่งที่มีประโยชน์และมีประสิทธิผลเพียงแค่มุ่งความสนใจไปที่สิ่งนั้น ความสามารถพิเศษนี้เองที่ทำให้พวกมันแตกต่างจากสิ่งมีชีวิตอื่นๆ และทำให้พวกเขาโดดเด่นและมีเอกลักษณ์เฉพาะตัว มนุษย์มีความสามารถในการเรียนรู้และนำความรู้ไปใช้ในชีวิตประจำวันต่างจากสิ่งมีชีวิตอื่นๆ ด้วยสำนึกแห่งความเชื่อ พวกเขาสามารถบรรลุทุกสิ่งที่จิตใจคิดและเปลี่ยนแปลงทุกสิ่งที่พวกเขาต้องการ",
  in:
    "Manusia memiliki kemampuan untuk mengubah non-entitas menjadi entitas yang berguna dan produktif hanya dengan memfokuskan pikiran mereka padanya. Kemampuan khusus inilah yang membedakan mereka dari makhluk lain dan membuat mereka luar biasa dan unik. Berbeda dengan makhluk lain, manusia memiliki kemampuan untuk belajar dan menerapkan ilmunya dalam kehidupan sehari-hari. Dengan rasa percaya mereka, mereka dapat mencapai apa pun yang dipikirkan oleh pikiran mereka dan mengubah apa pun yang mereka inginkan."
};

export const POWERTOBELIEVE = {
  en: "Power to Believe",
  ms: "Kekuatan untuk Percaya",
  ct: "相信的力量",
  cs: "相信的力量",
  th: "พลังแห่งศรัทธา",
  in: "Kekuatan untuk Percaya"
};

export const SIGNOUT = {
  en: "Sign Out",
  ms: "Keluar",
  ct: "登出",
  cs: "登出",
  th: "ออกจากระบบ",
  in: "Keluar"
};

export const EDITPROFILE = {
  en: "Edit Profile",
  ms: "Sunting profil",
  ct: "編輯個人資料",
  cs: "编辑个人资料",
  th: "แก้ไขโปรไฟล์",
  in: "Sunting profil"
};

export const MALE = {
  en: "Male",
  ms: "Lelaki",
  ct: "男性",
  cs: "男性",
  th: "ชาย",
  in: "Lelaki"
};

export const FEMALE = {
  en: "Female",
  ms: "Perempuan",
  ct: "女性",
  cs: "女性",
  th: "หญิง",
  in: "Perempuan"
};

export const PREFERNOTSAY = {
  en: "Prefer not to say",
  ms: "Lebih baik tak perlu cakap",
  ct: "寧願不說",
  cs: "宁愿不说",
  th: "ชอบที่จะไม่พูด",
  in: "Memilih untuk tidak mengatakan"
};

export const BIRTHDAY = {
  en: "Birthday",
  ms: "Hari Lahir",
  ct: "生日",
  cs: "生日",
  th: "วันเกิด",
  in: "Hari ulang tahun"
};

export const SELECTYOURBIRTHDAY = {
  en: "Please select your birthday!",
  ms: "Sila pilih hari lahir anda!",
  ct: "請選擇您的生日！",
  cs: "请选择您的生日！",
  th: "กรุณาเลือกวันเกิดของคุณ!",
  in: "Silakan pilih tanggal lahir Anda!"
};

export const FREEONLINEBOOK = {
  en: "Free Online Books",
  ms: "Buku Percuma Dalam Talian",
  ct: "免費在線書籍",
  cs: "免费在线书籍",
  th: "หนังสือออนไลน์ฟรี",
  in: "Buku Online Gratis"
};

export const LOGIN = {
  en: "Log In",
  ms: "Log masuk",
  ct: "登錄",
  cs: "登录",
  th: "เข้าสู่ระบบ",
  in: "Gabung"
};

export const SINCE = {
  en: "Since",
  ms: "SEJAK",
  ct: "自從",
  cs: "自从",
  th: "ตั้งแต่",
  in: "SEJAK"
};

export const WEHELPYOUTO = {
  en: "We help you to",
  ms: "Kami membantu anda untuk",
  ct: "我們幫助您",
  cs: "我们帮助您",
  th: "เราช่วยคุณได้",
  in: "Kami membantu Anda untuk"
};

export const ATTRACTENDLESSLEADS = {
  en: "attract endless Leads",
  ms: "menarik petunjuk yang tidak berkesudahan",
  ct: "吸引無盡的線索",
  cs: "吸引无尽的线索",
  th: "ดึงดูด Leads ที่ไม่มีที่สิ้นสุด",
  in: "menarik Leads yang tak ada habisnya"
};

export const LANDINGDESC = {
  en:
    "Discover what your spline isn’t telling you about how to automatically attract endless leads, prospects, customers, reps & clients into your business.",
  ms:
    "Ketahui apa yang tidak diberitahu oleh limpa anda mengenai cara menarik prospek, prospek, pelanggan, wakil & pelanggan tanpa henti ke dalam perniagaan anda secara automatik.",
  ct:
    "發現您的脾臟沒有告訴您如何自動吸引無窮無盡的潛在客戶、潛在客戶、客戶、代表和客戶進入您的業務。",
  cs:
    "发现您的脾脏没有告诉您如何自动吸引无穷无尽的潜在客户、潜在客户、客户、代表和客户进入您的业务。",
  th:
    "ค้นพบสิ่งที่ม้ามของคุณไม่ได้บอกคุณเกี่ยวกับวิธีการดึงดูดลูกค้าเป้าหมาย ผู้มีแนวโน้ม ลูกค้า ตัวแทน และลูกค้าเข้าสู่ธุรกิจของคุณโดยอัตโนมัติ",
  in:
    "Temukan apa yang tidak diberitahukan oleh limpa Anda tentang cara secara otomatis menarik prospek, pelanggan, perwakilan & klien tanpa akhir ke dalam bisnis Anda."
};

export const SCROLLDOWN = {
  en: "Scroll Down",
  ms: "Tatal ke Bawah",
  ct: "向下滾動",
  cs: "向下滚动",
  th: "เลื่อนลง",
  in: "Gulir ke Bawah"
};

export const ABOUT = {
  en: "ABOUT",
  ms: "TENTANG",
  ct: "關於",
  cs: "关于",
  th: "เกี่ยวกับ",
  in: "TENTANG"
};

export const LANDINGBIGTITLE = {
  en:
    "We’re the first and only established knowledge online institution for the Direct Selling Industry.",
  ms:
    "Kami adalah institusi pengetahuan dalam talian yang pertama dan satu-satunya untuk Industri Penjualan Langsung.",
  ct: "我們是第一家也是唯一一家成熟的直銷行業知識在線機構。",
  cs: "我们是第一家也是唯一一家成熟的直销行业知识在线机构。",
  th:
    "เราเป็นสถาบันความรู้ออนไลน์แห่งแรกและแห่งเดียวที่จัดตั้งขึ้นสำหรับอุตสาหกรรมขายตรง",
  in:
    "Kami adalah lembaga online pengetahuan pertama dan satu-satunya yang didirikan untuk Industri Penjualan Langsung."
};

export const LANDINGSUBTITLE1 = {
  en: "Master Direct Selling",
  ms: "Jualan Langsung Induk",
  ct: "大師直銷",
  cs: "大师直销",
  th: "ปริญญาโทขายตรง",
  in: "Master Penjualan Langsung"
};

export const LANDINGSUBDESC1 = {
  en:
    "Master the art and science of direct selling and network marketing. Unleash your potential for success in business and in life.",
  ms:
    "Menguasai seni dan sains penjualan langsung dan pemasaran rangkaian. Gunakan potensi anda untuk berjaya dalam perniagaan dan kehidupan.",
  ct: "掌握直銷和網絡營銷的藝術和科學。 釋放您在商業和生活中取得成功的潛力。",
  cs: "掌握直销和网络营销的艺术和科学。 释放您在商业和生活中取得成功的潜力。",
  th:
    "เชี่ยวชาญด้านศิลปะและวิทยาศาสตร์ของการขายตรงและการตลาดแบบเครือข่าย ปลดปล่อยศักยภาพของคุณเพื่อความสำเร็จในธุรกิจและในชีวิต",
  in:
    "Kuasai seni dan ilmu penjualan langsung dan pemasaran jaringan. Lepaskan potensi Anda untuk sukses dalam bisnis dan kehidupan."
};

export const LANDINGSUBTITLE2 = {
  en: "Fully Responsive",
  ms: "responsif sepenuhnya",
  ct: "完全響應",
  cs: "完全响应",
  th: "ตอบสนองอย่างเต็มที่",
  in: "sepenuhnya Responsif"
};

export const LANDINGSUBDESC2 = {
  en: "Avoid common pitfalls and maximise your effectiveness and income.",
  ms:
    "Elakkan perangkap biasa dan memaksimumkan keberkesanan dan pendapatan anda.",
  ct: "避免常見的陷阱，最大限度地提高您的效率和收入。",
  cs: "避免常见的陷阱，最大限度地提高您的效率和收入。",
  th: "หลีกเลี่ยงข้อผิดพลาดทั่วไปและเพิ่มประสิทธิภาพและรายได้ของคุณให้สูงสุด",
  in: "Hindari jebakan umum dan maksimalkan efektivitas dan pendapatan Anda."
};

export const LANDINGSUBTITLE3 = {
  en: "Team Collaboration",
  ms: "團隊協作",
  ct: "團隊協作",
  cs: "团队协作",
  th: "การทำงานร่วมกันเป็นทีม",
  in: "Kolaborasi Tim"
};

export const LANDINGSUBDESC3 = {
  en:
    "Get certificed and recognised for your knowledge, professionalism and effort.",
  ms:
    "Dapatkan sijil dan pengiktirafan untuk pengetahuan, profesionalisme dan usaha anda.",
  ct: "因您的知識、專業精神和努力獲得認證和認可。",
  cs: "因您的知识、专业精神和努力获得认证和认可。",
  th:
    "รับการรับรองและเป็นที่ยอมรับสำหรับความรู้ ความเป็นมืออาชีพ และความพยายามของคุณ",
  in:
    "Dapatkan sertifikasi dan pengakuan atas pengetahuan, profesionalisme, dan upaya Anda."
};

export const LANDINGSUBTITLE4 = {
  en: "Generate Income",
  ms: "Jana Pendapatan",
  ct: "創收",
  cs: "创收",
  th: "สร้างรายได้",
  in: "Hasilkan Penghasilan"
};

export const LANDINGSUBDESC4 = {
  en:
    "Generate residual and passive income with the potential of making thousands of dollar every month.",
  ms:
    "Jana pendapatan baki dan pasif dengan potensi menghasilkan ribuan dolar setiap bulan.",
  ct: "產生剩餘和被動收入，每月有可能賺取數千美元。",
  cs: "产生剩余和被动收入，每月有可能赚取数千美元。",
  th:
    "สร้างรายได้คงเหลือและรายได้แบบพาสซีฟด้วยศักยภาพในการสร้างรายได้หลายพันดอลลาร์ทุกเดือน",
  in:
    "Hasilkan pendapatan residual dan pasif dengan potensi menghasilkan ribuan dolar setiap bulan."
};

export const LANDINGBIGTITLE2 = {
  en:
    "What we do it’s for our clients. We help our clients to build their Brand, not just a Business",
  ms:
    "Apa yang kami lakukan adalah untuk pelanggan kami. Kami membantu pelanggan kami untuk membina Jenama mereka, bukan hanya Perniagaan",
  ct:
    "我們所做的一切都是為了我們的客戶。 我們幫助客戶建立他們的品牌，而不僅僅是一項業務",
  cs:
    "我们所做的一切都是为了我们的客户。 我们帮助客户建立他们的品牌，而不仅仅是一项业务",
  th:
    "สิ่งที่เราทำเพื่อลูกค้าของเรา เราช่วยลูกค้าของเราสร้างแบรนด์ ไม่ใช่แค่ธุรกิจ",
  in:
    "Apa yang kami lakukan adalah untuk klien kami. Kami membantu klien kami untuk membangun Merek mereka, bukan hanya Bisnis"
};

export const LANDINGDESC2 = {
  en:
    "Join 1 million + people learning on MLMBizMastery. The complete guide to what you need to know to be success at Network Marketing",
  ms:
    "Sertailah 1 juta + orang belajar di MLMBizMastery. Panduan lengkap mengenai perkara yang perlu anda ketahui untuk berjaya di Network Marketing",
  ct:
    "加入 100 萬多人在 MLMBizMastery 上學習。 在網絡營銷方面取得成功所需了解的完整指南",
  cs:
    "加入 100 万多人在 MLMBizMastery 上学习。 在网络营销方面取得成功所需了解的完整指南",
  th:
    "เข้าร่วม 1 ล้านคน + การเรียนรู้บนพอร์ทัลธุรกิจ คู่มือฉบับสมบูรณ์เกี่ยวกับสิ่งที่คุณต้องรู้เพื่อความสำเร็จที่ Network Marketing",
  in:
    "Bergabunglah dengan 1 juta + orang belajar di Portal Bisnis. Panduan lengkap tentang apa yang perlu Anda ketahui untuk menjadi sukses di Network Marketing"
};

export const CREATIVITY = {
  en: "Creativity",
  ms: "Kreativiti",
  ct: "創造力",
  cs: "创造力",
  th: "ความคิดสร้างสรรค์",
  in: "Kreativitas"
};

export const CARDDESC1 = {
  en:
    "Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Sed risus pretium quam vulputate dignissim suspendisse in est. Faucibus ornare suspendisse sed nisi lacus. Ut aliquam purus sit amet luctus venenatis",
  ms: "",
  ct: "",
  cs: "",
  th: "",
  in: ""
};

export const INNOVATION = {
  en: "Innovation",
  ms: "Inovasi",
  ct: "革新",
  cs: "革新",
  th: "นวัตกรรม",
  in: "Inovasi"
};

export const CARDDESC3 = {
  en:
    "Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Sed risus pretium quam vulputate dignissim suspendisse in est. Faucibus ornare suspendisse sed nisi lacus. Ut aliquam purus sit amet luctus venenatis",
  ms: "",
  ct: "",
  cs: "",
  th: "",
  in: ""
};

export const INSPIRATION = {
  en: "Inspiration",
  ms: "Inspirasi",
  ct: "靈感",
  cs: "灵感",
  th: "แรงบันดาลใจ",
  in: "Inspirasi"
};

export const CARDDESC2 = {
  en:
    "Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Sed risus pretium quam vulputate dignissim suspendisse in est. Faucibus ornare suspendisse sed nisi lacus. Ut aliquam purus sit amet luctus venenatis",
  ms: "",
  ct: "",
  cs: "",
  th: "",
  in: ""
};

export const DIRECTORY = {
  en: "Directory",
  ms: "Direktori",
  ct: "目錄",
  cs: "目录",
  th: "ไดเรกทอรี",
  in: "Direktori"
};

export const DISCOVERY = {
  en: "Discovery",
  ms: "Penemuan",
  ct: "發現",
  cs: "发现",
  th: "การค้นพบ",
  in: "Penemuan"
};

export const CARDDESC4 = {
  en:
    "Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Sed risus pretium quam vulputate dignissim suspendisse in est. Faucibus ornare suspendisse sed nisi lacus. Ut aliquam purus sit amet luctus venenatis",
  ms: "",
  ct: "",
  cs: "",
  th: "",
  in: ""
};

export const IDEA = {
  en: "Idea",
  ms: "Idea",
  ct: "主意",
  cs: "主意",
  th: "ความคิด",
  in: "Ide"
};

export const CARDDESC5 = {
  en:
    "Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Sed risus pretium quam vulputate dignissim suspendisse in est. Faucibus ornare suspendisse sed nisi lacus. Ut aliquam purus sit amet luctus venenatis",
  ms: "",
  ct: "",
  cs: "",
  th: "",
  in: ""
};

export const IMAGINATION = {
  en: "Imagination",
  ms: "Khayalan",
  ct: "想像力",
  cs: "想像力",
  th: "จินตนาการ",
  in: "Imajinasi"
};

export const CARDDESC6 = {
  en:
    "Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Sed risus pretium quam vulputate dignissim suspendisse in est. Faucibus ornare suspendisse sed nisi lacus. Ut aliquam purus sit amet luctus venenatis",
  ms: "",
  ct: "",
  cs: "",
  th: "",
  in: ""
};

export const LANDINGBIGTITLE3 = {
  en: "Learn to grow your business without promoting your products",
  ms: "Belajar mengembangkan perniagaan anda tanpa mempromosikan produk anda ",
  ct: "學習在不推廣產品的情況下發展業務",
  cs: "学习在不推广产品的情况下发展业务",
  th: "เรียนรู้ที่จะขยายธุรกิจของคุณโดยไม่ต้องโปรโมตผลิตภัณฑ์ของคุณ",
  in: "Belajar mengembangkan bisnis Anda tanpa mempromosikan produk Anda"
};

export const LANDINGDESC3 = {
  en:
    "Master direct selling (network marketing, MLM) and unleash your potential for success in business and in life",
  ms:
    "Kuasai penjualan langsung (pemasaran rangkaian, MLM) dan manfaatkan potensi anda untuk berjaya dalam perniagaan dan kehidupan",
  ct: "掌握直銷（網絡營銷、傳銷）並釋放您在商業和生活中取得成功的潛力",
  cs: "掌握直销（网络营销、传销）并释放您在商业和生活中取得成功的潜力",
  th:
    "หลักการขายตรง (การตลาดแบบเครือข่าย, MLM) และปลดปล่อยศักยภาพของคุณเพื่อความสำเร็จในธุรกิจและในชีวิต",
  in:
    "Kuasai penjualan langsung (pemasaran jaringan, MLM) dan lepaskan potensi Anda untuk sukses dalam bisnis dan kehidupan"
};

export const marqueeText1 = {
  en: "Turn Your Dreams Into Reality",
  ms: "Ubah Impian Anda Menjadi Realiti",
  ct: "將您的夢想變為現實",
  cs: "将您的梦想变为现实",
  th: "เปลี่ยนความฝันของคุณให้เป็นจริง",
  in: "Ubah Impian Anda Menjadi Kenyataan"
};
export const marqueeText2 = {
  en: "Definition Of Power",
  ms: "Definisi Kuasa",
  ct: "權力的定義",
  cs: "权力的定义",
  th: "ความหมายของอำนาจ",
  in: "Definisi Kekuatan"
};
export const marqueeText3 = {
  en: "Aspire For Greater Things",
  ms: "Aspirasi Untuk Perkara Yang Lebih Besar",
  ct: "追求更偉大的事物",
  cs: "追求更伟大的事物",
  th: "Aspire For Greater Things",
  in: "Bercita-cita Untuk Hal-Hal yang Lebih Besar"
};
export const marqueeText4 = {
  en: "Reconnect With Your Dream",
  ms: "Sambung semula dengan Impian Anda",
  ct: "重新連接你的夢想",
  cs: "重新连接你的梦想",
  th: "เชื่อมต่อกับความฝันของคุณอีกครั้ง",
  in: "Hubungkan Kembali Dengan Impian Anda"
};
export const marqueeText5 = {
  en: "The Power Of Faith",
  ms: "Kekuatan Iman",
  ct: "信仰的力量",
  cs: "信仰的力量",
  th: "พลังแห่งศรัทธา",
  in: "Kekuatan Iman"
};
export const marqueeText6 = {
  en: "Power Of Positive Thinking",
  ms: "Kekuatan Berfikir Positif",
  ct: "積極思考的力量",
  cs: "积极思考的力量",
  th: "พลังแห่งการคิดบวก",
  in: "Kekuatan Berpikir Positif"
};
export const marqueeText7 = {
  en: "Professional Selling Skills",
  ms: "Kemahiran Menjual Profesional",
  ct: "專業的銷售技巧",
  cs: "专业的销售技巧",
  th: "ทักษะการขายอย่างมืออาชีพ",
  in: "Keterampilan Menjual Profesional"
};
export const marqueeText8 = {
  en: "Essential Communication",
  ms: "Komunikasi Penting",
  ct: "基本溝通",
  cs: "基本沟通",
  th: "การสื่อสารที่จำเป็น",
  in: "Komunikasi Penting"
};
export const marqueeText9 = {
  en: "Turning Yearly Income",
  ms: "Menjana Pendapatan Tahunan",
  ct: "年收入",
  cs: "年收入",
  th: "เปลี่ยนรายได้ประจำปี",
  in: "Mengubah Pendapatan Tahunan"
};
export const marqueeText10 = {
  en: "Definition Of Power",
  ms: "Definisi Kuasa",
  ct: "權力的定義",
  cs: "权力的定义",
  th: "ความหมายของอำนาจ",
  in: "Definisi Kekuatan"
};
export const marqueeText11 = {
  en: "Believe In Yourself",
  ms: "Percaya pada Diri",
  ct: "相信你自己",
  cs: "相信你自己",
  th: "เชื่อในตัวคุณเอง",
  in: "Percaya pada dirimu sendiri"
};

export const marqueeText12 = {
  en: "Aspire For Greater Things",
  ms: "Aspirasi Untuk Perkara Yang Lebih Besar",
  ct: "追求更偉大的事物",
  cs: "追求更伟大的事物",
  th: "Aspire For Greater Things",
  in: "Bercita-cita Untuk Hal-Hal yang Lebih Besar"
};
export const marqueeText13 = {
  en: "The Desire To Succeed",
  ms: "Keinginan untuk Berjaya",
  ct: "渴望成功",
  cs: "渴望成功",
  th: "ความปรารถนาที่จะประสบความสำเร็จ",
  in: "Keinginan Untuk Sukses"
};
export const marqueeText14 = {
  en: "Deal With A Problem",
  ms: "Mengatasi Masalah",
  ct: "處理問題",
  cs: "处理问题",
  th: "จัดการกับปัญหา",
  in: "Mengatasi Masalah"
};
export const marqueeText15 = {
  en: "Power Of Logic",
  ms: "Kekuatan Logik",
  ct: "邏輯的力量",
  cs: "逻辑的力量",
  th: "พลังแห่งตรรกะ",
  in: "Kekuatan Logika"
};
export const marqueeText16 = {
  en: "Positive Manner",
  ms: "Adab Positif",
  ct: "積極的態度",
  cs: "积极的态度",
  th: "ทัศนคติเชิงบวก",
  in: "Cara Positif"
};
export const marqueeText17 = {
  en: "Cultivate A New Belief",
  ms: "Memupuk Kepercayaan Baru",
  ct: "培養新的信念",
  cs: "培养新的信念",
  th: "ปลูกฝังความเชื่อใหม่",
  in: "Kembangkan Keyakinan Baru"
};
export const marqueeText18 = {
  en: "Network Marketing",
  ms: "Pemasaran rangkaian",
  ct: "網絡營銷",
  cs: "网络营销",
  th: "เครือข่ายการตลาด",
  in: "Jaringan pemasaran"
};
export const marqueeText19 = {
  en: "Motivate Your Distributors",
  ms: "Motivasikan Pengedar Anda",
  ct: "激勵您的經銷商",
  cs: "激励您的经销商",
  th: "กระตุ้นผู้จัดจำหน่ายของคุณ",
  in: "Motivasi Distributor Anda"
};
export const marqueeText20 = {
  en: "Nothing Is Too Difficult",
  ms: "Tidak ada yang terlalu sukar",
  ct: "沒有什麼太難的",
  cs: "没有什么太难的",
  th: "ไม่มีอะไรยากเกินไป",
  in: "Tidak Ada yang Terlalu Sulit"
};
export const marqueeText21 = {
  en: "Ready To Get Going",
  ms: "Bersedia Untuk Melangkah",
  ct: "準備開始",
  cs: "准备开始",
  th: "พร้อมลุย",
  in: "Siap Berangkat"
};
export const marqueeText22 = {
  en: "Maintain Your Desire",
  ms: "Kekalkan Keinginan Anda",
  ct: "保持你的慾望",
  cs: "保持你的欲望",
  th: "รักษาความปรารถนาของคุณ",
  in: "Pertahankan Keinginan Anda"
};
export const marqueeText23 = {
  en: "Empower Yourself",
  ms: "Memperkasakan Diri",
  ct: "賦能自己",
  cs: "赋能自己",
  th: "เติมพลังให้ตัวเอง",
  in: "Berdayakan Diri Anda"
};
export const marqueeText24 = {
  en: "Self Help Books",
  ms: "Buku Bantuan Diri",
  ct: "自助書籍",
  cs: "自助书籍",
  th: "หนังสือช่วยเหลือตนเอง",
  in: "Buku Bantuan Mandiri"
};
export const marqueeText25 = {
  en: "Positive Signals",
  ms: "Isyarat Positif",
  ct: "積極信號",
  cs: "积极信号",
  th: "สัญญาณบวก",
  in: "Sinyal Positif"
};
export const marqueeText26 = {
  en: "Improve Your Self Image",
  ms: "Tingkatkan Imej Diri Anda",
  ct: "改善你的自我形象",
  cs: "改善你的自我形象",
  th: "ปรับปรุงภาพลักษณ์ของคุณ",
  in: "Tingkatkan Citra Diri Anda"
};
export const marqueeText27 = {
  en: "Workshop Training",
  ms: "Latihan Bengkel",
  ct: "車間培訓",
  cs: "车间培训",
  th: "อบรมเชิงปฏิบัติการ",
  in: "Pelatihan Lokakarya"
};
export const marqueeText28 = {
  en: "Effective Communication",
  ms: "Komunikasi Berkesan",
  ct: "有效溝通",
  cs: "有效沟通",
  th: "การสื่อสารที่มีประสิทธิภาพ",
  in: "Komunikasi yang efektif"
};
export const marqueeText29 = {
  en: "Strong And Powerful Environment",
  ms: "Persekitaran yang Kuat dan Berkuasa",
  ct: "強而有力的環境",
  cs: "强而有力的环境",
  th: "สภาพแวดล้อมที่แข็งแกร่งและทรงพลัง",
  in: "Lingkungan yang Kuat Dan Kuat"
};
export const marqueeText30 = {
  en: "Overcome Fear",
  ms: "Atasi Ketakutan",
  ct: "克服恐懼",
  cs: "克服恐惧",
  th: "เอาชนะความกลัว",
  in: "Mengatasi Ketakutan"
};

export const marqueeText31 = {
  en: "4 Step Cycle",
  ms: "Kitaran 4 Langkah",
  ct: "4 步循環",
  cs: "4 步循环",
  th: "รอบ 4 ขั้นตอน",
  in: "Siklus 4 Langkah"
};
export const marqueeText32 = {
  en: "Marketing Process",
  ms: "Proses Pemasaran",
  ct: "營銷流程",
  cs: "营销流程",
  th: "กระบวนการทางการตลาด",
  in: "Proses Pemasaran"
};
export const marqueeText33 = {
  en: "Core Marketing Message",
  ms: "Mesej Pemasaran Teras",
  ct: "核心營銷信息",
  cs: "核心营销信息",
  th: "ข้อความการตลาดหลัก",
  in: "Pesan Pemasaran Inti"
};
export const marqueeText34 = {
  en: "5P's Of Marketing",
  ms: "Pemasaran 5P",
  ct: "營銷的5P",
  cs: "营销的5P",
  th: "5P ของการตลาด",
  in: "Pemasaran 5P"
};
export const marqueeText35 = {
  en: "Unique Compeititve Advantage (UCA)",
  ms: "Kelebihan Persaingan Unik (UCA)",
  ct: "獨特的競爭優勢 (UCA)",
  cs: "独特的竞争优势 (UCA)",
  th: "ความได้เปรียบในการแข่งขัน (UCA)",
  in: "Keunggulan Kompetitif Unik (UCA)"
};
export const marqueeText36 = {
  en: "Business Speaking",
  ms: "Pengucapan Perniagaan",
  ct: "商務演講",
  cs: "商务演讲",
  th: "การพูดทางธุรกิจ",
  in: "Berbicara Bisnis"
};
export const marqueeText37 = {
  en: "Mind Mapping Skills",
  ms: "Kemahiran Pemetaan Minda",
  ct: "思維導圖技能",
  cs: "思维导图技能",
  th: "ทักษะการทำแผนที่ความคิด",
  in: "Keterampilan Pemetaan Pikiran"
};

export const LANDINGBIGTITLE4 = {
  en: "Our Awesome Plan",
  ms: "Pelan Hebat Kami",
  ct: "我們很棒的計劃",
  cs: "我们很棒的计划",
  th: "แผนสุดเจ๋งของเรา",
  in: "Rencana Luar Biasa Kami"
};

export const LANDINGDESC4 = {
  en:
    "Get these monthly plan. Dive in and commit to online network marketing mastery today!",
  ms:
    "Dapatkan rancangan bulanan ini. Ikuti dan ikuti penguasaan pemasaran rangkaian dalam talian hari ini!",
  ct: "獲取這些月度計劃。 立即投入並致力於掌握在線網絡營銷！",
  cs: "获取这些月度计划。 立即投入并致力于掌握在线网络营销！",
  th:
    "รับแผนรายเดือนเหล่านี้ เจาะลึกและมุ่งมั่นสู่ความเชี่ยวชาญด้านการตลาดเครือข่ายออนไลน์วันนี้!",
  in:
    "Dapatkan paket bulanan ini. Selami dan berkomitmen untuk penguasaan pemasaran jaringan online hari ini!"
};

export const PREMIUM = {
  en: "Premium",
  ms: "Premium",
  ct: "優質的",
  cs: "优质的",
  th: "พรีเมี่ยม",
  in: "Premium"
};

export const BASIC = {
  en: "Basic",
  ms: "Asas",
  ct: "基本的",
  cs: "基本的",
  th: "ขั้นพื้นฐาน",
  in: "Dasar"
};

export const USERS = {
  en: "Users",
  ms: "Pengguna",
  ct: "用戶",
  cs: "用户",
  th: "ผู้ใช้",
  in: "Pengguna"
};

export const MAX = {
  en: "Max",
  ms: "Maks",
  ct: "最大限度",
  cs: "最大限度",
  th: "แม็กซ์",
  in: "Maks"
};

export const EBOOKACCESS = {
  en: "E-Book Access",
  ms: "Akses E-Book",
  ct: "電子書訪問",
  cs: "电子书访问",
  th: "Akses E-Buku",
  in: "Akses E-Buku"
};

export const FREE = {
  en: "Free",
  ms: "Percuma",
  ct: "自由",
  cs: "自由",
  th: "ฟรี",
  in: "Gratis"
};

export const USER = {
  en: "/user",
  ms: "/pengguna",
  ct: "/用戶",
  cs: "/用戶",
  th: "/ผู้ใช้",
  in: "/pengguna"
};

export const UPTO = {
  en: "Up to",
  ms: "Sehingga",
  ct: "取決於",
  cs: "取决于",
  th: "จนถึง",
  in: "Hingga"
};

export const ADVANCED = {
  en: "Advanced",
  ms: "Mahir",
  ct: "先進的",
  cs: "先进的",
  th: "ขั้นสูง",
  in: "Canggih"
};

export const UNLIMITED = {
  en: "Unlimited",
  ms: "Tanpa had",
  ct: "無限",
  cs: "无限",
  th: "ไม่ จำกัด",
  in: "Tak terbatas"
};

export const GOTQUESTION = {
  en: "Got a question about MLMBizMastery??",
  ms: "Ada soalan mengenai MLMBizMastery ??",
  ct: "有關於 MLMBizMastery 的問題？",
  cs: "有关于 MLMBizMastery 的问题？",
  th: "มีคำถามเกี่ยวกับพอร์ทัลธุรกิจ ??",
  in: "Punya pertanyaan tentang MLMBizMastery??"
};

export const OURTEAMNINJASREADYTOHEAR = {
  en: "Our team of customer care ninjas is ready to hear from you",
  ms:
    "Pasukan ninja perkhidmatan pelanggan kami bersedia untuk mendengar daripada anda",
  ct: "我們的客戶服務忍者團隊隨時準備聽取您的意見",
  cs: "我们的客户服务忍者团队随时准备听取您的意见",
  th: "ทีมนินจาดูแลลูกค้าของเราพร้อมที่จะรับฟังจากคุณ",
  in: "Tim ninja layanan pelanggan kami siap mendengar dari Anda"
};

export const REACHOUTTOUS = {
  en: "Reach out to us!",
  ms: "Hubungi kami!",
  ct: "聯繫我們！",
  cs: "联系我们！",
  th: "ยื่นมือมาหาเรา!",
  in: "Hubungi kami!"
};

export const NETWORKMARKETER = {
  en: "Network Marketer",
  ms: "Pemasar Rangkaian",
  ct: "網絡營銷員",
  cs: "网络营销员",
  th: "นักการตลาดเครือข่าย",
  in: "Pemasar Jaringan"
};

export const GETSTARTEDNOW = {
  en: "Get Started Now",
  ms: "Mulakan sekarang",
  ct: "現在就開始",
  cs: "现在就开始",
  th: "เริ่มตอนนี้เลย",
  in: "Mulai sekarang"
};

export const ITSFREETOJOINANDGAIN = {
  en:
    "It's free to join and gain full access to thousands of exciting investment opportunities.",
  ms:
    "Percuma untuk bergabung dan mendapatkan akses penuh ke ribuan peluang pelaburan yang menarik.",
  ct: "它可以免費加入並獲得數以千計的令人興奮的投資機會。",
  cs: "它可以免费加入并获得数以千计的令人兴奋的投资机会。",
  th: "เข้าร่วมได้ฟรีและเข้าถึงโอกาสการลงทุนที่น่าตื่นเต้นมากมาย",
  in:
    "Gratis untuk bergabung dan mendapatkan akses penuh ke ribuan peluang investasi yang menarik."
};

export const MAXPASSWORD = {
  en: "The minimum password length is 6",
  ms: "Panjang kata laluan minimum ialah 6",
  ct: "最小密碼長度為 6",
  cs: "最小密码长度为 6",
  th: "ความยาวรหัสผ่านขั้นต่ำคือ 6",
  in: "Panjang kata sandi minimum adalah 6"
};

export const PLEASEENTERPASSWORD = {
  en: "Please enter your password",
  ms: "Sila masukkan kata laluan anda",
  ct: "請輸入您的密碼",
  cs: "请输入您的密码",
  th: "กรุณาใส่รหัสผ่านของคุณ",
  in: "Silakan masukkan kata sandi Anda"
};

export const PASSWORD = {
  en: "Password",
  ms: "Kata Laluan",
  ct: "密碼",
  cs: "密码",
  th: "รหัสผ่าน",
  in: "Kata sandi"
};

export const PLEASEENTERECODE = {
  en: "Please provide an E-Code.",
  ms: "Sila berikan E-Code.",
  ct: "請提供電子代碼。",
  cs: "请提供电子代码。",
  th: "โปรดระบุ E-Code",
  in: "Harap berikan E-Code."
};

export const ECODE = {
  en: "E-Code",
  ms: "E-Kod",
  ct: "電子代碼",
  cs: "电子代码",
  th: "E-Code",
  in: "Kode Elektronik"
};

export const GENDER = {
  en: "Gender",
  ms: "Jantina",
  ct: "性別",
  cs: "性别",
  th: "เพศ",
  in: "Jenis kelamin"
};

export const PLEASESELECTGENDER = {
  en: "Please state your gender.",
  ms: "Nyatakan jantina anda.",
  ct: "請說明您的性別。",
  cs: "请说明您的性别。",
  th: "กรุณาระบุเพศของคุณ",
  in: "Silakan sebutkan jenis kelamin Anda."
};

export const PLEASESELECTBIRTHDAY = {
  en: "Please state your birthday",
  ms: "Sila nyatakan hari lahir anda",
  ct: "請說明你的生日",
  cs: "请说明你的生日",
  th: "โปรดระบุวันเกิดของคุณ",
  in: "Tolong sebutkan tanggal lahirmu"
};

export const PRIVACYPOLICY = {
  en: "I agree to the website's Privacy Policy & Terms and Conditions",
  ms: "Saya bersetuju dengan Dasar Privasi & Terma dan Syarat laman web",
  ct: "我同意網站的隱私政策和條款和條件",
  cs: "我同意网站的隐私政策和条款和条件",
  th: "ฉันยอมรับนโยบายความเป็นส่วนตัวและข้อกำหนดและเงื่อนไขของเว็บไซต์",
  in: "Saya setuju dengan Kebijakan Privasi & Syarat dan Ketentuan situs web"
};

export const SIGNUP = {
  en: "SIGN UP",
  ms: "DAFTAR",
  ct: "報名",
  cs: "报名",
  th: "ลงชื่อ",
  in: "DAFTAR"
};

export const ALREADYHAVEACCOUNT = {
  en: "Already have an account?",
  ms: "Sudah mempunyai akaun?",
  ct: "已經有賬戶？",
  cs: "已经有账户？",
  th: "มีบัญชีอยู่แล้ว?",
  in: "Sudah memiliki akun?"
};

export const SIGNIN = {
  en: "Sign In",
  ms: "Log masuk",
  ct: "登入",
  cs: "登入",
  th: "เข้าสู่ระบบ",
  in: "Masuk"
};

export const HERE = {
  en: "here",
  ms: "di sini",
  ct: "這裡",
  cs: "这里",
  th: "ที่นี่",
  in: "di sini"
};

export const DATE = {
  en: "Date",
  ms: "Tarikh",
  ct: "日期",
  cs: "日期",
  th: "วันที่",
  in: "Tanggal"
};

export const PLEASEACCEPTPRIVACYPOLICY = {
  en: "Please accept the privacy policy & terms and conditions.",
  ms: "Terimalah polisi & terma dan syarat privasi.",
  ct: "請接受隱私政策和條款和條件。",
  cs: "请接受隐私政策和条款和条件。",
  th: "โปรดยอมรับนโยบายความเป็นส่วนตัวและข้อกำหนดและเงื่อนไข",
  in: "Harap setujui kebijakan privasi & syarat dan ketentuan."
};

export const LOGINTOGETFULLACCESS = {
  en: "Log in now to get full accesss.",
  ms: "Log masuk sekarang untuk mendapatkan akses penuh.",
  ct: "立即登錄以獲得完全訪問權限。",
  cs: "立即登录以获得完全访问权限。",
  th: "เข้าสู่ระบบตอนนี้เพื่อเข้าถึงแบบเต็ม",
  in: "Masuk sekarang untuk mendapatkan akses penuh."
};

export const LOGINSMALL = {
  en: "Login",
  ms: "Log Masuk",
  ct: "登錄",
  cs: "登录",
  th: "เข้าสู่ระบบ",
  in: "Gabung"
};

export const LOGINCAP = {
  en: "LOGIN",
  ms: "LOG MASUK",
  ct: "登錄",
  cs: "登录",
  th: "เข้าสู่ระบบ",
  in: "GABUNG"
};

export const FORGOTPASSWORD = {
  en: "Forgot your password?",
  ms: "Lupa kata laluan anda?",
  ct: "忘記密碼了嗎？",
  cs: "忘记密码了吗？",
  th: "ลืมรหัสผ่านหรือไม่?",
  in: "Lupa kata sandi anda?"
};

export const NOTREGISTERED = {
  en: "Not registered yet? Click here to sign up",
  ms: "Belum berdaftar? Klik di sini untuk mendaftar",
  ct: "還沒註冊？ 點擊這裡註冊",
  cs: "还没注册？ 点击这里注册",
  th: "ยังไม่ได้ลงทะเบียน? คลิกที่นี่เพื่อสมัคร",
  in: "Belum terdaftar? Klik di sini untuk mendaftar"
};

export const ENTEREMAILTORESET = {
  en: "Please enter your email to reset your password",
  ms: "Masukkan e-mel anda untuk menetapkan semula kata laluan anda",
  ct: "請輸入您的電子郵件以重置您的密碼",
  cs: "请输入您的电子邮件以重置您的密码",
  th: "กรุณากรอกอีเมล์เพื่อรีเซ็ตรหัสผ่าน",
  in: "Silakan masukkan email Anda untuk mengatur ulang kata sandi Anda"
};

export const EMAILTORESET = {
  en: "Email to reset",
  ms: "E-mel untuk menetapkan semula",
  ct: "要重置的電子郵件",
  cs: "要重置的电子邮件",
  th: "อีเมลที่จะรีเซ็ต",
  in: "Email untuk mengatur ulang"
};

export const RESET = {
  en: "Reset",
  ms: "Tetapkan semula",
  ct: "重置",
  cs: "重置",
  th: "รีเซ็ต",
  in: "Mengatur ulang"
};

export const CANCEL = {
  en: "Cancel",
  ms: "Batal",
  ct: "取消",
  cs: "取消",
  th: "ยกเลิก",
  in: "Membatalkan"
};

export const PROVIDENEWECODETORENEWACCOUNT = {
  en: "Please provide a new E-Code to renew your account",
  ms: "Sila berikan E-Code baru untuk memperbaharui akaun anda",
  ct: "請提供新的電子代碼以更新您的帳戶",
  cs: "请提供新的电子代码以更新您的帐户",
  th: "โปรดระบุ E-Code ใหม่เพื่อต่ออายุบัญชีของคุณ",
  in: "Harap berikan E-Code baru untuk memperbarui akun Anda"
};

export const ENTERYOUROUTLINE = {
  en: "Enter your outline here",
  ms: "Masukkan garis besar anda di sini",
  ct: "在此處輸入您的大綱",
  cs: "在此处输入您的大纲",
  th: "ใส่โครงร่างของคุณที่นี่",
  in: "Masukkan garis besar anda di sini"
};

export const BOOKBACKGROUND = {
  en: "Book Background",
  ms: "Latar Belakang Buku",
  ct: "書籍背景",
  cs: "书籍背景",
  th: "พื้นหลังหนังสือ",
  in: "Latar Belakang Buku"
};

export const NAMEOFBOOK = {
  en: "Name of Book",
  ms: "Nama Buku",
  ct: "書名",
  cs: "书名",
  th: "ชื่อหนังสือ",
  in: "Nama Buku"
};

export const FONTVARIANT = {
  en: "Font Variant",
  ms: "Varian Font",
  ct: "字體變體",
  cs: "字体变体",
  th: "Font Variant",
  in: "Varian Font"
};

export const FONTTYPE = {
  en: "Font Type",
  ms: "Jenis Fon",
  ct: "字體類型",
  cs: "字体类型",
  th: "แบบอักษร",
  in: "Jenis huruf"
};

export const COLOR = {
  en: "Color",
  ms: "Warna",
  ct: "顏色",
  cs: "颜色",
  th: "สี",
  in: "Warna"
};

export const FONTSIZE = {
  en: "Font Size",
  ms: "Saiz huruf",
  ct: "字體大小",
  cs: "字体大小",
  th: "ขนาดตัวอักษร",
  in: "Ukuran huruf"
};

export const YOUNAMEORCOMPANY = {
  en: "Your Name or Company Name",
  ms: "Nama Anda atau Nama Syarikat",
  ct: "您的姓名或公司名稱",
  cs: "您的姓名或公司名称",
  th: "ชื่อของคุณหรือชื่อบริษัท",
  in: "Nama Anda atau Nama Perusahaan"
};

export const BOOKTAGLINE1 = {
  en: "Book Tagline 1",
  ms: "Tagline Buku 1",
  ct: "預訂標語 1",
  cs: "预订标语 1",
  th: "สโลแกนหนังสือ 1",
  in: "Tagline Buku 1"
};

export const BOOKTAGLINE2 = {
  en: "Book Tagline 2",
  ms: "Tagline Buku 2",
  ct: "預訂標語 2",
  cs: "预订标语 2",
  th: "สโลแกนหนังสือ 2",
  in: "Tagline Buku 2"
};

export const BOTTOMDESCRIPTION = {
  en: "Bottom Description",
  ms: "Penerangan Bawah",
  ct: "底部說明",
  cs: "底部说明",
  th: "คำอธิบายด้านล่าง",
  in: "Deskripsi Bawah"
};
export const EDITBOTTOMDESCRIPTION = {
  en: "Edit bottom description here",
  ms: "Edit keterangan bawah di sini",
  ct: "在此處編輯底部描述",
  cs: "在此处编辑底部描述",
  th: "แก้ไขคำอธิบายด้านล่างที่นี่",
  in: "Edit deskripsi bawah di sini"
};

export const ENTERYOURDESCRIPTION = {
  en: "Enter your description here",
  ms: "Masukkan keterangan anda di sini",
  ct: "在此處輸入您的描述",
  cs: "在此处输入您的描述",
  th: "ใส่คำอธิบายของคุณที่นี่",
  in: "Masukkan deskripsi Anda di sini"
};

export const EDITBOOKCOVERHERE = {
  en: "Edit book cover here",
  ms: "Edit kulit buku di sini",
  ct: "在這裡編輯書籍封面",
  cs: "在这里编辑书籍封面",
  th: "แก้ไขปกหนังสือที่นี่",
  in: "Edit sampul buku di sini"
};

export const EDITTITLEANDOUTLINE = {
  en: "Edit title & outline here",
  ms: "Edit tajuk & garis besar di sini",
  ct: "在此處編輯標題和大綱",
  cs: "在此处编辑标题和大纲",
  th: "แก้ไขชื่อและโครงร่างที่นี่",
  in: "Edit judul & garis besar di sini"
};

export const EDITBUTTONTEXT = {
  en: "Edit button text here",
  ms: "Edit teks butang di sini",
  ct: "在此處編輯按鈕文本",
  cs: "在此处编辑按钮文本",
  th: "แก้ไขข้อความปุ่มที่นี่",
  in: "Edit teks tombol di sini"
};

export const BUTTONNAME = {
  en: "Button Name",
  ms: "Nama Butang",
  ct: "按鈕名稱",
  cs: "按钮名称",
  th: "ชื่อปุ่ม",
  in: "Nama Butang"
};
export const BUTTONNAMECANNOTBEEMPTY = {
  en: "Button name cannot be empty",
  ms: "Nama butang tidak boleh kosong",
  ct: "按鈕名稱不能為空",
  cs: "按钮名称不能为空",
  th: "ชื่อปุ่มไม่สามารถเว้นว่างได้",
  in: "Nama butang tidak boleh kosong"
};

export const FACEBOOKURL = {
  en: "Facebook Url",
  ms: "Url Facebook",
  ct: "臉書網址",
  cs: "脸书网址",
  th: "Facebook Url",
  in: "Url Facebook"
};
export const FACEBOOKURLCANNOTBEEMPTY = {
  en: "Facebook url cannot be empty",
  ms: "Url Facebook tidak boleh kosong",
  ct: "Facebook 網址不能為空",
  cs: "Facebook 网址不能为空",
  th: "URL ของ Facebook ไม่สามารถเว้นว่างได้",
  in: "Url Facebook tidak boleh kosong"
};

export const OUTLINE = {
  en: "Outline",
  ms: "Garis besar",
  ct: "大綱",
  cs: "大纲",
  th: "เค้าร่าง",
  in: "Garis besar"
};
export const OUTLINECANNOTBEEMPTY = {
  en: "Outline cannot be empty",
  ms: "Garis besar tidak boleh kosong",
  ct: "大綱不能為空",
  cs: "大纲不能为空",
  th: "โครงร่างไม่สามารถเว้นว่างได้",
  in: "Garis besar tidak boleh kosong"
};

export const HIDE = {
  en: "Hide",
  ms: "Sembunyikan",
  ct: "隱藏",
  cs: "隐藏",
  th: "ซ่อน",
  in: "Bersembunyi"
};

export const SAVINGSALESFUNNEL = {
  en: "Saving funnel page. Please wait...",
  ms: "Menyimpan halaman corong. Sila tunggu...",
  ct: "保存漏斗頁面。 請稍等...",
  cs: "保存漏斗页面。 请稍等...",
  th: "บันทึกหน้าช่องทาง โปรดรอ...",
  in: "Menyimpan halaman corong. Mohon tunggu..."
};

export const LOADING = {
  en: "Loading...",
  ms: "Memuat ...",
  ct: "正在加載...",
  cs: "正在加载...",
  th: "กำลังโหลด...",
  in: "Memuat..."
};

export const SAVINGEXAMPROGRESS = {
  en: "Saving exam progress...",
  ms: "Menyimpan kemajuan peperiksaan ...",
  ct: "正在保存考試進度...",
  cs: "正在保存考试进度...",
  th: "กำลังบันทึกความคืบหน้าของการสอบ...",
  in: "Menyimpan kemajuan ujian..."
};

export const WELCOME = {
  en: "Welcome",
  ms: "Selamat datang",
  ct: "歡迎",
  cs: "欢迎",
  th: "ยินดีต้อนรับ",
  in: "Selamat datang"
};

export const WEREEXCITED = {
  en:
    "We’re excited to show you around the best place on Earth to learn Marketing, Professional Selling Skills, Public Speaking and even much more!",
  ms:
    "Kami teruja untuk menunjukkan kepada anda tempat terbaik di Bumi untuk belajar Pemasaran, Kemahiran Menjual Profesional, Pengucapan Awam dan banyak lagi!",
  ct:
    "我們很高興向您展示地球上學習營銷、專業銷售技巧、公開演講等等的最佳地點！",
  cs:
    "我们很高兴向您展示地球上学习营销、专业销售技巧、公开演讲等等的最佳地点！",
  th:
    "เรารู้สึกตื่นเต้นที่จะแสดงให้คุณเห็นสถานที่ที่ดีที่สุดในโลกเพื่อเรียนรู้การตลาด ทักษะการขายอย่างมืออาชีพ การพูดในที่สาธารณะ และอื่นๆ อีกมากมาย!",
  in:
    "Kami senang menunjukkan kepada Anda tempat terbaik di Bumi untuk belajar Pemasaran, Keterampilan Penjualan Profesional, Berbicara di Depan Umum, dan bahkan lebih banyak lagi!"
};

export const STARTUSEDO = {
  en: "Start, Use, DO",
  ms: "Mula, Gunakan, JANGAN",
  ct: "開始、使用、做",
  cs: "开始、使用、做",
  th: "เริ่ม ใช้ ทำ",
  in: "Mulai, Gunakan, LAKUKAN"
};

export const STARTWHEREYOUARE = {
  en: "Start where you are, Use what you have, Do what you can.",
  ms:
    "Mulakan di mana anda berada, Gunakan apa yang anda ada, Lakukan apa yang anda boleh.",
  ct: "從你所在的地方開始，利用你所擁有的，做你能做的。",
  cs: "从你所在的地方开始，利用你所拥有的，做你能做的。",
  th: "เริ่มต้นจากที่ที่คุณอยู่ ใช้สิ่งที่คุณมี ทำในสิ่งที่คุณทำได้",
  in:
    "Mulailah dari mana Anda berada, Gunakan apa yang Anda miliki, Lakukan apa yang Anda bisa."
};

export const MARKATING = {
  en: "Marketing",
  ms: "Pemasaran",
  ct: "營銷",
  cs: "营销",
  th: "การตลาด",
  in: "Pemasaran"
};

export const MARKATINGTAKESADAY = {
  en: "Marketing takes a day to learn and a lifetime to master - Philip Kotler",
  ms:
    "Pemasaran memerlukan satu hari untuk belajar dan seumur hidup untuk dikuasai - Philip Kotler",
  ct: "營銷需要一天的時間來學習，需要一生的時間來掌握——菲利普·科特勒",
  cs: "营销需要一天的时间来学习，需要一生的时间来掌握——菲利普·科特勒",
  th:
    "การตลาดใช้เวลาหนึ่งวันในการเรียนรู้ และใช้เวลาทั้งชีวิตเพื่อเชี่ยวชาญ - Philip Kotler",
  in:
    "Pemasaran membutuhkan satu hari untuk dipelajari dan seumur hidup untuk dikuasai - Philip Kotler"
};

export const CHANGEYOURMINDSET = {
  en: "Change your Mindset",
  ms: "Ubah Mindset anda",
  ct: "改變你的心態",
  cs: "改变你的心态",
  th: "เปลี่ยนความคิดของคุณ",
  in: "Ubah Pola Pikir Anda"
};

export const NOTINGISIMPOSSIBLE = {
  en: "Nothing is impossible, The word itself says “I’m possible”",
  ms: "Tidak ada yang mustahil, kata itu sendiri mengatakan “Saya mungkin”",
  ct: "沒有什麼是不可能的，這個詞本身就說“我有可能”",
  cs: "没有什么是不可能的，这个词本身就在说“我有可能”",
  th: "ไม่มีอะไรที่เป็นไปไม่ได้ คำว่า “เป็นไปได้”",
  in: "Tidak ada yang tidak mungkin, Kata itu sendiri mengatakan “Saya mungkin”"
};

export const WHICHOFTHESEDESCRIBEYOUBEST = {
  en: "Which of these describes you best?",
  ms: "Manakah antara yang paling sesuai untuk anda?",
  ct: "以下哪一項最能描述您？",
  cs: "以下哪一项最能描述您？",
  th: "ข้อใดอธิบายตัวคุณได้ดีที่สุด",
  in: "Manakah dari ini yang paling menggambarkan Anda?"
};

export const TAKEMETOPORTAL = {
  en: "TAKE ME TO MLMBizMastery",
  ms: "BAWA SAYA KE PORTAL PERNIAGAAN",
  ct: "帶我去商業門戶",
  cs: "带我去商业门户",
  th: "พาฉันไปที่พอร์ทัลธุรกิจ",
  in: "BUKTIKAN SAYA KE PORTAL BISNIS"
};

export const APROFESSIONAL = {
  en: "A professional ",
  ms: "Seorang profesional ",
  ct: "專業的 ",
  cs: "专业的 ",
  th: "มืออาชีพ ",
  in: "Seorang profesional "
};

export const LOOKINGTOSHARPENYOURSKILLS = {
  en:
    "looking to sharpen your skills and unleash potential for success in business and life",
  ms:
    "ingin mengasah kemahiran anda dan melepaskan potensi kejayaan dalam perniagaan dan kehidupan",
  ct: "希望提高您的技能並釋放在商業和生活中取得成功的潛力",
  cs: "希望提高您的技能并释放在商业和生活中取得成功的潜力",
  th: "ต้องการฝึกฝนทักษะและปลดปล่อยศักยภาพสู่ความสำเร็จในธุรกิจและชีวิต",
  in:
    "mencari untuk mempertajam keterampilan Anda dan melepaskan potensi untuk sukses dalam bisnis dan kehidupan"
};

export const ANENTREPRENEUR = {
  en: "An entrepreneur ",
  ms: "Seorang usahawan ",
  ct: "企業家 ",
  cs: "企业家 ",
  th: "ผู้ประกอบการ ",
  in: "Seorang pengusaha "
};

export const LOOKINGTOINCREASEYOURINCOME = {
  en: "looking to increase your income and influence as a business owner",
  ms:
    "ingin meningkatkan pendapatan dan pengaruh anda sebagai pemilik perniagaan",
  ct: "希望增加您作為企業主的收入和影響力",
  cs: "希望增加您作为企业主的收入和影响力",
  th: "ต้องการเพิ่มรายได้และอิทธิพลของคุณในฐานะเจ้าของธุรกิจ",
  in: "ingin meningkatkan penghasilan dan pengaruh Anda sebagai pemilik bisnis"
};

export const AHOBBYISTORENTHUSIAST = {
  en: "A hobbyist or enthusiast ",
  ms: "Penggemar atau peminat ",
  ct: "愛好者或愛好者 ",
  cs: "爱好者或爱好者 ",
  th: "งานอดิเรกหรือผู้ที่ชื่นชอบ ",
  in: "Seorang penghobi atau peminat "
};

export const JUSTLOOKINGTOOKEEPMYMINDSHARP = {
  en: "just looking too keep my mind sharp",
  ms: "hanya melihat terlalu tajam fikiran saya",
  ct: "只是看起來太讓我頭腦清醒",
  cs: "只是看起来太让我头脑清醒",
  th: "แค่มองก็ทำให้จิตใจเฉียบแหลม",
  in: "hanya melihat terlalu menjaga pikiranku tetap tajam"
};

export const NONEOFABOVE = {
  en: "None of the above",
  ms: "Tiada perkara di atas",
  ct: "以上都不是",
  cs: "以上都没有",
  th: "ไม่มีข้างต้น",
  in: "Bukan dari salah satu di atas"
};

export const FAILEDEXAM = {
  en: "Sorry, You've failed your exam! Try again.",
  ms: "Maaf, anda gagal dalam peperiksaan! Cuba lagi.",
  ct: "對不起，你考試不及格！ 再試一次。",
  cs: "对不起，你考试不及格！ 再试一次。",
  th: "ขออภัย คุณสอบตก! ลองอีกครั้ง.",
  in: "Maaf, Anda telah gagal dalam ujian Anda! Coba lagi."
};

export const PASSWORDREQUIRED = {
  en: "Password must contain at least 1 number, 1 alphabet and 8 character long",
  ms: "Kata laluan mesti mengandungi sekurang-kurangnya 1 nombor, 1 abjad dan 8 aksara",
  ct: "密碼必須至少包含 1 個數字、1 個字母和 8 個字符長",
  cs: "密码必须至少包含 1 个数字、1 个字母和 8 个字符长",
  th: "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว ตัวอักษร 1 ตัวและยาว 8 ตัว",
  in: "Kata sandi harus mengandung setidaknya 1 angka, 1 alfabet, dan panjang 8 karakter"
};

export const QUESTION = {
  en: "Question",
  ms: "Soalan",
  ct: "題",
  cs: "题",
  th: "คำถาม",
  in: "Pertanyaan"
};

// export const HERE = {
//   en: "here",
//   ms: "Seterusnya",
//   ct: "下一個",
//   cs: "下一个",
//   th: "ถัดไป",
//   in: "Berikutnya"
// };
