import { LOAD_TRANSLATION_DATA } from "./translationConstant";

export const loadTranslationData = () => {

    return async (dispatch, getState, { getFirebase }) => {
        const db = getFirebase().firestore();
        try {

            let transQuerySnap = await db.collection("translations").get();

            //create own internal map ...
            let translationObj = {}
            transQuerySnap.forEach(docSnap => {

                let data = docSnap.data()

                if (typeof translationObj[data.languageCode] === "undefined") {
                    translationObj[data.languageCode] = {}
                }
                Object.keys(data.translations).forEach(transText => {
                    translationObj[data.languageCode][transText] = data.translations[transText]
                })
            })

            dispatch({ type: LOAD_TRANSLATION_DATA, payload: { translationObj } })

        } catch (err) {
            console.log(err)
            throw(err)
        }
    }
}
